import React, { Component } from "react";

import _ from "lodash";
//import { FormControl, Dropdown } from 'glic-ui-components';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../../store/HomePage/HomePageAction";
import { scrollUp } from "../../../utils/sharedFunction";
import SaveForLater from "../../../components/SaveForLater/SaveForLater";

class HeightWeight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numeric: /^[0-9]+$/i,
      selfInfo: [],
      hwInfo: {},
      bannerFlag: false,
      employeeCovered: false,
      spouseCovered: false,
      childCovered: false,
      paramId: '',
      isSaved: false,
      isOpened: false
    }
  }

  componentDidMount = () => {
    document.title = "Height and Weight | Group EOI - Guardian";
    // const { id } = this.props.match.params;
    // console.log("htwt-paramsId-", id);
    // this.setState({ paramId: id})
    // console.log("htwt-paramsId4-", this.state.paramId);

    if (_.get(this.props, "prefillData.eoi_data.health_question_info.updateHtandWt_status", "") !== "COMPLETED") {
      _.set(this.props, "prefillData.eoi_data.health_question_info.updateHtandWt_status", "ACTIVE")
      if (this.props.trustedFlag === true) {
        this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""))
      }
    }


    // console.log("dd", this.props);
    let { trustedFlag } = this.props;
    let entityArray = [];

    //trustedFlag = true;
    if (trustedFlag === true) {
      let child = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
      let spouse = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", []);
      let employee = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
      let life_entities = _.get(this.props, "prefillData.eoi_data.health_question_info.life_entities", []);
      // let personal_information = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", []);

      // let Arrrya = {

      // }
      let a = []
      // console.log(life_entities)
      // console.log(child);
      // console.log({...life_entities,...child});
      // let childArray = []
      for (let i = 0; i < life_entities.length; i++) {
        if (life_entities[i].life_base_qstn_req === true) {
          life_entities[i]["first_name"] = life_entities[i].life_name.split(", ")[1];
          if (life_entities[i].life_desc === "EMPLOYEE") {
            a = [...a, employee]
          }
          if (life_entities[i].life_desc === "SPOUSE") {
            a = [...a, spouse]
          }

          if (life_entities[i].life_desc === "CHILD") {

            // for(let k=0; k<child.length;k++) {
            // a = [...a,child[k]]
            // }

          }
          entityArray.push(life_entities[i]);
        }
      }
      if (child !== null || child !== undefined) {
        child && child.map(each => {
          a = [...a, each]
          return each
        })
      }

      // // console.log(a)

      // console.log("entityArray", entityArray);
      let hwInfo = {}
      for (let i = 0; i < a.length; i++) {
        hwInfo[i + '_weight'] = a[i].weight;
        hwInfo[i + '_heightInch'] = a[i].height_inches;
        hwInfo[i + '_heightFeet'] = a[i].height_feet;
      }
      // console.log(hwInfo);
      this.setState({ "hwInfo": hwInfo });


    } else {
      let whoCovered = _.get(this.props, "whoCovered", "");
      let child = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
      let spouse = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", []);
      let employee = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
      // console.log(employee,"eeeeeeeeee")
      if (whoCovered === "Me, my spouse, and my child(ren)") {
        entityArray = [employee, spouse];
        for (let i = 0; i < child.length; i++) {
          entityArray.push(child[i]);
          ///// console.log("entityArray", entityArray);
        }
      } else if (whoCovered === "Me") {
        entityArray = [employee];
      } else if (whoCovered === "My spouse") {
        entityArray = [spouse]
      } else if (whoCovered === "My spouse and child" && child.length === 1) {
        entityArray.push(spouse);
        for (let i = 0; i < child.length; i++) {
          entityArray.push(child[i]);
        }
      }
      else if (whoCovered === "My spouse and child" && child.length > 1) {
        entityArray.push(spouse);
        for (let i = 0; i < child.length; i++) {
          entityArray.push(child[i]);
        }
      } else if (whoCovered === "My child" && child.length === 1) {
        entityArray = [child]
      } else if (whoCovered === "Me and my spouse") {
        entityArray = [employee, spouse]
      } else if (whoCovered === "My child" && child.length > 1) {
        for (let i = 0; i < child.length; i++) {
          entityArray.push(child[i]);
        }
      } else if (whoCovered === "Me and my child" && child.length === 1) {
        entityArray.push(employee);
        for (let i = 0; i < child.length; i++) {
          entityArray.push(child[i]);
        }
      }
      else if (whoCovered === "Me and my child" && child.length > 1) {
        entityArray.push(employee);
        for (let i = 0; i < child.length; i++) {
          entityArray.push(child[i]);
        }
      }
      let hwInfo = {}
      for (let i = 0; i < entityArray.length; i++) {
        hwInfo[i + '_weight'] = entityArray[i].weight;
        hwInfo[i + '_heightInch'] = entityArray[i].height_inches;
        hwInfo[i + '_heightFeet'] = entityArray[i].height_feet;
      }
      // console.log(hwInfo);
      this.setState({ "hwInfo": hwInfo });
    }

    this.setState({ "selfInfo": entityArray });
    scrollUp();
    document.getElementsByClassName('showbanner')[0].style.display = 'none';

    const coverages = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {});
    const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability"]
    let coverageElected = []
    let allEmployeeStatus = []
    let allSpouseStatus = []
    let allChildrenStatus = []

    for (const coverage in coverages) {
      let disabilityCoverageElectedStatus = false
      let employeeCoveredStatus = false
      let spouseCoveredStatus = false
      let childCoveredStatus = false
      if (disabilityCoverages.includes(coverage)) {

        disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
      }
      else {
        employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
        spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
        childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered
      }

      // console.log(employeeCoveredStatus,spouseCoveredStatus,childCoveredStatus,disabilityCoverageElectedStatus)
      if (employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus === true || disabilityCoverageElectedStatus === true) {
        coverageElected.push(coverage)
      }
      allEmployeeStatus.push(employeeCoveredStatus)
      allEmployeeStatus.push(disabilityCoverageElectedStatus)
      allSpouseStatus.push(spouseCoveredStatus)
      allChildrenStatus.push(childCoveredStatus)
    }

    // this.state.employeeCovered = allEmployeeStatus.includes(true);
    // this.state.spouseCovered = allSpouseStatus.includes(true);
    // this.state.childCovered = allChildrenStatus.includes(true);
    this.setState({
      // coveragesElected : coverageElected,
      employeeCovered: allEmployeeStatus.includes(true),
      spouseCovered: allSpouseStatus.includes(true),
      childCovered: allChildrenStatus.includes(true),
      // displaySpouse: spouse_available

    })

  }

  onSubmit = (e) => {
    var hwInfo = { ...this.state.hwInfo };
    let entityArray = _.get(this.state, "selfInfo", []);
    let showError = false;
    let { trustedFlag } = this.props;
    let firstInvalidElement = null;

    for (let i = 0; i < entityArray.length; i++) {
      if (_.get(this.state, `hwInfo.` + i + `_heightFeet`, "") <= 0) {
        hwInfo[i + '_heightFeetError'] = true;
        showError = true;
        this.setState({ hwInfo });
      }

      if (_.get(this.state, `hwInfo.` + i + `_heightInch`, "") <= 0) {
        hwInfo[i + '_heightInchError'] = true;
        showError = true;
        this.setState({ hwInfo });
      }

      if (_.get(this.state, `hwInfo.` + i + `_weight`, "") <= 0) {
        hwInfo[i + '_weightError'] = true;
        showError = true;
        this.setState({ hwInfo });
      }    
    }
    let substring = /Error$/;
    let index = 0;
    const errors =  Object.entries(hwInfo).filter(([key, value ]) => substring.test(key));
    const errorsArray = Object.fromEntries(errors)
    const errorsArrayFormatted = Object.entries(errorsArray).map(([key, value])=>{
      if (value === true)
        return key
      else 
        return ""
    }
    )
    for(let i=0; i<errorsArrayFormatted.length; i++){
      if(errorsArrayFormatted[i]){
        index = i;
        break;
      }
    }
    firstInvalidElement = document.getElementById(errorsArrayFormatted[index]);
    if (showError === true) {
      document.getElementsByClassName('showbanner')[0].style.display = 'block';
      if (firstInvalidElement) {
        firstInvalidElement.focus();
      }
    } else {
      let whoCovered = _.get(this.props, "whoCovered", "");
      let child = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);

      if (trustedFlag === true) {
        const { employeeCovered, spouseCovered, childCovered } = this.state;
        // employeeCovered = allEmployeeStatus.includes(true);
        // spouseCovered = allSpouseStatus.includes(true);
        // childCovered = allChildrenStatus.includes(true);
        // console.log("entityArray", entityArray);
        // console.log(employeeCovered, spouseCovered, childCovered)

        if (employeeCovered === true && spouseCovered === true && childCovered === true) {
          for (let i = 0; i < entityArray.length; i++) {
            if (entityArray[i].life_base_qstn_req) {
              if (entityArray[i].life_desc === "EMPLOYEE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));
              }
              if (entityArray[i].life_desc === "SPOUSE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));

              }
            }
            for (let k = 0; k < child.length; k++) {
              // console.log(_.get(this.state, `hwInfo.` + i + `_heightFeet`, ""), k)
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_feet`, _.get(this.state, `hwInfo.` + (k + 2) + `_heightFeet`, ""));
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_inches`, _.get(this.state, `hwInfo.` + (k + 2) + `_heightInch`, ""));

              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].weight`, _.get(this.state, `hwInfo.` + (k + 2) + `_weight`, ""));
              // console.log(_.get(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information`))

            }
          }
        } else if (employeeCovered === true && spouseCovered === true && childCovered === false) {
          for (let i = 0; i < entityArray.length; i++) {
            if (entityArray[i].life_base_qstn_req) {
              if (entityArray[i].life_desc === "EMPLOYEE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));
              }
              if (entityArray[i].life_desc === "SPOUSE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));

              }
            }
          }
        } else if (employeeCovered === true && spouseCovered === false && childCovered === false) {
          for (let i = 0; i < entityArray.length; i++) {
            if (entityArray[i].life_base_qstn_req) {
              if (entityArray[i].life_desc === "EMPLOYEE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));
              }
            }
          }
        } else if (employeeCovered === false && spouseCovered === true && childCovered === false) {
          for (let i = 0; i < entityArray.length; i++) {
            if (entityArray[i].life_base_qstn_req) {
              if (entityArray[i].life_desc === "SPOUSE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));

              }
            }
          }

        } else if (employeeCovered === false && spouseCovered === false && childCovered === true) {
          for (let i = 0; i < entityArray.length; i++) {
            for (let k = 0; k < child.length; k++) {
              // console.log(_.get(this.state, `hwInfo.` + i + `_heightFeet`, ""), k)
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_feet`, _.get(this.state, `hwInfo.` + (k) + `_heightFeet`, ""));
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_inches`, _.get(this.state, `hwInfo.` + (k) + `_heightInch`, ""));
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].weight`, _.get(this.state, `hwInfo.` + (k) + `_weight`, ""));
              // console.log(_.get(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information`))

            }
          }

        } else if (employeeCovered === true && spouseCovered === false && childCovered === true) {
          for (let i = 0; i < entityArray.length; i++) {
            if (entityArray[i].life_base_qstn_req) {
              if (entityArray[i].life_desc === "EMPLOYEE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));
              }
            }
            for (let k = 0; k < child.length; k++) {
              // console.log(_.get(this.state, `hwInfo.` + i + `_heightFeet`, ""), k)
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_feet`, _.get(this.state, `hwInfo.` + (k + 1) + `_heightFeet`, ""));
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_inches`, _.get(this.state, `hwInfo.` + (k + 1) + `_heightInch`, ""));

              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].weight`, _.get(this.state, `hwInfo.` + (k + 1) + `_weight`, ""));
              // console.log(_.get(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information`))

            }
          }
        }
        else if (employeeCovered === false && spouseCovered === true && childCovered === true) {
          for (let i = 0; i < entityArray.length; i++) {
            if (entityArray[i].life_base_qstn_req) {
              if (entityArray[i].life_desc === "SPOUSE") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, `hwInfo.` + i + `_weight`, ""));

              }
            }
            for (let k = 0; k < child.length; k++) {
              // console.log(_.get(this.state, `hwInfo.` + i + `_heightFeet`, ""), k)
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_feet`, _.get(this.state, `hwInfo.` + (k + 1) + `_heightFeet`, ""));
              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].height_inches`, _.get(this.state, `hwInfo.` + (k + 1) + `_heightInch`, ""));

              _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + k + `].weight`, _.get(this.state, `hwInfo.` + (k + 1) + `_weight`, ""));
              // console.log(_.get(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information`))

            }
          }
        }

      } else {

        if (whoCovered === "Me, my spouse, and my child(ren)") {
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, "hwInfo.0_weight", ""));

          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, "hwInfo.1_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, "hwInfo.1_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, "hwInfo.1_weight", ""));

          // console.log("child length", child.length);
          for (let i = 0; i < child.length; i++) {
            // console.log("test", _.get(this.state, `hwInfo.`+(i+2)+`_heightFeet`, ""));
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_feet`, _.get(this.state, `hwInfo.` + (i + 2) + `_heightFeet`, ""));
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_inches`, _.get(this.state, `hwInfo.` + (i + 2) + `_heightInch`, ""));

            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].weight`, _.get(this.state, `hwInfo.` + (i + 2) + `_weight`, ""));

          }
        } else if (whoCovered === "Me") {
          // console.log("hh", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, "hwInfo.0_weight", ""));

        } else if (whoCovered === "My spouse") {
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, "hwInfo.0_weight", ""));


        } else if (whoCovered === "My child" && child.length === 1) {
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].weight", _.get(this.state, "hwInfo.0_weight", ""));


        } else if (whoCovered === "Me and my spouse") {

          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, "hwInfo.0_weight", ""));

          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, "hwInfo.1_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, "hwInfo.1_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, "hwInfo.1_weight", ""));

        } else if (whoCovered === "My child" && child.length > 1) {
          for (let i = 0; i < child.length; i++) {
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_feet`, _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_inches`, _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));

            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].weight`, _.get(this.state, `hwInfo.` + i + `_weight`, ""));
          }
        }
        else if (whoCovered === "My spouse and child" && child.length === 1) {
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, "hwInfo.0_weight", ""));

          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].weight", _.get(this.state, "hwInfo.0_weight", ""));

        }
        else if (whoCovered === "My spouse and child" && child.length > 1) {
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", _.get(this.state, "hwInfo.0_weight", ""));

          for (let i = 0; i < child.length; i++) {
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_feet`, _.get(this.state, `hwInfo.` + i + `_heightFeet`, ""));
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_inches`, _.get(this.state, `hwInfo.` + i + `_heightInch`, ""));

            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].weight`, _.get(this.state, `hwInfo.` + i + `_weight`, ""));
          }

        }
        else if (whoCovered === "Me and my child" && child.length === 1) {
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, "hwInfo.0_weight", ""));

          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].height_feet", _.get(this.state, "hwInfo.1_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].height_inches", _.get(this.state, "hwInfo.1_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[0].weight", _.get(this.state, "hwInfo.1_weight", ""));

        }
        else if (whoCovered === "Me and my child" && child.length > 1) {
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", _.get(this.state, "hwInfo.0_heightFeet", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", _.get(this.state, "hwInfo.0_heightInch", ""));
          _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", _.get(this.state, "hwInfo.0_weight", ""));

          for (let i = 0; i < child.length; i++) {
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_feet`, _.get(this.state, `hwInfo.` + (i + 1) + `_heightFeet`, ""));
            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].height_inches`, _.get(this.state, `hwInfo.` + (i + 1) + `_heightInch`, ""));

            _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + i + `].weight`, _.get(this.state, `hwInfo.` + (i + 1) + `_weight`, ""));
          }
        }
      }

      // console.log("bbbb", this.props);

      if (_.get(this.props, "prefillData.eoi_data.health_question_info.updateHtandWt_status", "") === "ACTIVE") {
        _.set(this.props, "prefillData.eoi_data.health_question_info.updateHtandWt_status", "COMPLETED")
        // this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
      }
      //need to call the save service
      // console.log(this.props.prefillData)
      // this.props.saveHeightWeight(_.get(this.props, "prefillData", ""));

      let previousURL = _.get(this.props, "location.state.previousPage")
      if (previousURL === "review") {
        // this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/review')
        this.props.saveHeightWeight(_.get(this.props, "prefillData", ""));
        this.props.isFlag1(true);

        const { id } = this.props.match.params;
        if (id && id) {
          this.props.chevron(true);
        }
        this.props.history.push(`/eoi/review/${id}`)
      }
      else {
        //this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/homePage')
        this.props.saveHeightWeight(_.get(this.props, "prefillData", ""));
        this.props.history.push('/eoi/healthHistory');

      }
    }

  }

  changeTextObjectFields = (e, objectName, regEx) => {
    var hwInfo = { ...this.state.hwInfo };
    //// console.log("pageElepageEle", hwInfo);
    // console.log("objectName", objectName, e.target.value);
    if (e.target.value === '' || regEx.test(e.target.value)) {
      // console.log("inside");
      hwInfo[objectName] = e.target.value;
      //// console.log("pageElepageEle", hwInfo);
      this.setState({ hwInfo });
    }
  }

  handleClose = () => {
    document.getElementsByClassName('showbanner')[0].style.display = 'none';
  }

  blurWeight = (e, errorField) => {
    var hwInfo = { ...this.state.hwInfo };
    let value = e.target.value.trim();
    if (value && value.length > 0) {
      hwInfo[errorField] = false;
      this.setState({ hwInfo });
    }
    this.setState({ hwInfo }, () => {
      this.test();
    });
  }

  onHeightChange = (event, objectName) => {
    var hwInfo = { ...this.state.hwInfo };
    hwInfo[objectName] = event.target.value;
    this.setState({ hwInfo })
  }

  onBlurRequired = (e, errorField, heightOtherValue) => {
    var hwInfo = { ...this.state.hwInfo };
    let value = e.target.value.trim();


    if (value && value.length > 0 && hwInfo[heightOtherValue] && hwInfo[heightOtherValue].length > 0) {
      hwInfo[errorField] = false;
      this.setState({ hwInfo });
    }
    this.setState({ hwInfo }, () => {
      this.test();

    });
  }

  test = () => {
    // console.log("testtt");
    // console.log(document.getElementsByClassName("error").length);
    if (document.getElementsByClassName("error").length > 0) {
      document.getElementsByClassName('showbanner')[0].style.display = 'block';
    } else {
      document.getElementsByClassName('showbanner')[0].style.display = 'none';
    }
  }
  onBack = () => {
    let { trustedFlag } = this.props;
    if (!trustedFlag) {
      this.props.history.push(`/eoi/homePage`);
    } else {
      let hasKnockoutQuestion = _.get(this.props, "prefillData.eoi_data.health_question_info.has_knockout_question", "");
      if (hasKnockoutQuestion === false) {
        this.props.history.push(`/eoi/homePage`);
      }
      else if (hasKnockoutQuestion === true) {
        if (_.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.other_knockout_question") !== null
          && _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.other_knockout_question") !== undefined
          && _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.other_knockout_question").length > 0) {
          this.props.history.push('/eoi/otherKnockOutQ')
        }
        else if (_.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.cancer_knockout_question") !== null
          && _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.cancer_knockout_question") !== undefined
          && _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.cancer_knockout_question").length > 0) {
          this.props.history.push('/eoi/cancerKnockOutQ')
        } else if (_.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.critical_illness_knockout_question") !== null
          && _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.critical_illness_knockout_question") !== undefined
          && _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.critical_illness_knockout_question").length > 0) {
          this.props.history.push('/eoi/criticalKnockOutQ')
        } else {
          this.props.history.push(`/eoi/homePage`)
        }
      }
    }
  }
  saveForLater = () => {
    this.setState({ isSaved: true });
  }
  continueEOI = () => {
    this.setState({ isSaved: false });
  }
  leave = () => {
    this.props.history.push({ pathname: `/eoi/success` })
  }

  render() {
    let entityArray = _.get(this.state, "selfInfo", []);
    const check = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY"
    // console.log("entityArray-",entityArray);
    let { trustedFlag } = this.props;
    //ned to check has base qtns , if true go with hq pages elase other page
    // console.log("this.state.entityArray", entityArray);
    // console.log("this.stae", this.state);
    // console.log("this.state.hwInfo",this.state.hwInfo)
    //  // console.log(this.state.hwInfo._heightFeet);
    let feet = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let inches = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
    return (
      <>
        {this.state.isSaved === true ? <SaveForLater continueEOI={() => this.continueEOI()} leave={() => this.leave()} /> : null}
        <AlertBanner handleClose={() => this.handleClose()} errorMessage="Please fill out all required fields." />
        <main className="knockout">
          <div className="row question-bg gray-bg">
            <div className="container-fluid">
              <div className="prev-link">
                <button className="btn btn-link" onClick={() => this.onBack()}><i className="fa fa-arrow-left"></i>Back</button>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <h1 className="responsive-normal-heading" style={{ fontSize: '20px' }}>
                    {entityArray.length === 1 ? "Provide the height and weight" :
                      "Provide the height and weight of each member of your family."}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {entityArray.map((entityNum, index) => (
                  <div className={entityArray.length > 1 ? "question-box-border" : "question-box"}>
                    <fieldset>
                      {entityArray.length > 1 ? 
                      <legend className="data-label">{check === true ? entityNum.life_name.split(", ").slice(-1) : entityNum.first_name}</legend> : null}
                      <div className="form-group">
                        <label>Height</label>
                        <div className="question-form-group" style={{ display: "flex" }}>
                          <div>
                            <div style={{ display: "flex" }}>
                              <select className="form-control inline"
                                value={_.get(this.state, `hwInfo.` + index + `_heightFeet`, "")}
                                onBlur={(e) => this.onBlurRequired(e, index + `_heightFeetError`, index + `_heightFeet`)}
                                onChange={(e) => this.onHeightChange(e, index + `_heightFeet`)}
                                aria-describedby="height_feet_unit1 height_feet_error" id={index + "_heightFeetError"} aria-labelledby="height_feet_unit">
                                <option selected disabled></option>
                                {feet.map((feetNum, index) => (
                                  <option value={feetNum} key={index}>{feetNum}</option>
                                ))}
                              </select>
                              <span id="height_feet_unit1">feet</span>
                            </div>
                            <p id="height_feet_error">
                              {_.get(this.state, `hwInfo.` + index + `_heightFeetError`) ?
                                <span className="error"><i className="fas fa-exclamation-circle" aria-label="error" role="img"></i>Height is required in feet.</span> : null}
                            </p>
                          </div>
                          <div>
                            <div style={{ display: "flex" }}>
                              <select className="form-control inline"
                                value={_.get(this.state, `hwInfo.` + index + `_heightInch`, "")}
                                onBlur={(e) => this.onBlurRequired(e, index + `_heightInchError`, index + `_heightInch`)}
                                onChange={(e) => this.onHeightChange(e, index + `_heightInch`)}
                                id={index + "_heightInchError"}
                                aria-describedby="height_inch_error" aria-labelledby="height_inch_unit">
                                <option selected disabled></option>
                                {inches.map((inchNum, index) => (
                                  <option value={inchNum} key={index}>{inchNum}</option>
                                ))}
                              </select>
                              <span id="height_inch_unit">inches</span>
                            </div>
                            <p id="height_inch_error">
                              {_.get(this.state, `hwInfo.` + index + `_heightInchError`) ?
                                <span className="error"><i className="fas fa-exclamation-circle" aria-label="error" role="img"></i>Height is required in inches.</span> : null}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="form-group pt-4">
                        <label id="weight1">Weight</label>
                        <div className="question-form-group">
                          <input className="form-control inline weight" id={index + "_weightError"} maxLength="3"
                            onBlur={(e) => this.blurWeight(e, index + `_weightError`)}
                            value={_.get(this.state, `hwInfo.` + index + `_weight`, "")}
                            onChange={(e) => this.changeTextObjectFields(e, index + "_weight", this.state.numeric)}
                            aria-labelledby="weight1" aria-describedby="pound_unit weight_error" type="text" />
                          <span id="pound_unit">pounds</span>

                        </div>
                        <p id="weight_error">
                          {_.get(this.state, `hwInfo.` + index + `_weightError`) ?
                            <span className="error"><i class="fas fa-exclamation-circle" aria-label="error" role="img"></i>Weight is required</span> : null}
                        </p>
                      </div>
                    </fieldset> </div>))}


                <hr />
                <div className="field-group table-header-group">
                  {trustedFlag === true ? <button className="btn btn-link" onClick={() => this.saveForLater()}>Save for later</button> : null}
                  <button className="btn btn-primary float-right" onClick={(e) => this.onSubmit(e)}>
                    {_.get(this.props, "location.state.previousPage") === "review" ?
                      "Return to review page" : "Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

export default connect(
  (state) => state.eoiStore,
  (dispatch) => bindActionCreators(actionCreators, dispatch))(HeightWeight);
