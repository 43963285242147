import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators } from "../../store/HomePage/HomePageAction";
import { scrollUp, compareNestedObject } from "../../utils/sharedFunction";
import DuplicatePopup from "../../components/DuplicateSSN/DuplicatePopup";

class Mib extends Component {

    state = {
        empName: "",
        spouseName: "",
        childName: [],
        bannerFlag: false,
        isDuplicate: false,
        health_authorization: {
            form_authorization_link: "",
            form_consent_link: "",
            form_optout_link: "",
            employeeCovered: false,
            spouseCovered: false,
            childCovered: false
        },
        employee_mib_auth: false,
        employee_electronic_consent: false,
        spouse_mib_auth: false,
        spouse_electronic_consent: false,
        showEmpMibErr: false,
        showEmpAgreeErr: false,
        showSpouseMibErr: false,
        showSpouseAgreeErr: false,
        trustedUser: false,
        displayMember: false,
        displaySpouse: false,
        downloadPdfPageLink: '',
        childEligible: false,
        date: new Date().getFullYear(),
        child: [{
            showMibChildError: false,
            showAgreeChildError: false,
            child_mib_auth: false,
            child_electronic_consent: false,
            child_name: ""
        }],
        childInformation: []
    }

    onOptout = (e) => {
        e.preventDefault();
        let trusted = _.get(this.props, "prefillData.eoi_data", {});
        if (trusted.authenticated) {
            this.props.history.push({
                pathname: `/eoi/trustedPrintedEOI`,
                state: { "previousPage": "mib" }
            })
        }
        else {
            this.props.history.push({
                pathname: `/eoi/nonTrustedPrintedEOI`,
                state: { "previousPage": "mib" }
            })
        }
    }


    componentDidMount() {
        scrollUp()
        document.title = "Authorization | Group EOI - Guardian";
        let trustedData = _.get(this.props, "prefillData.eoi_data", {});
        let member_available = this.state.displayMember;
        let spouse_available = this.state.displaySpouse;
        let child_available = this.state.displayChild;
        let health_auth_data = this.state.health_authorization;
        // console.log("duplicateSSN",this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.duplicateSSN, this.props.isTrusted)

        // if(this.props.prefillData?.eoi_data?.benefit_coverage_info?.eoi_info?.duplicateSSN === true ){
        //     if (this.props.isTrusted === true ){
        //         this.setState({isDuplicate: false})
        // }
        // else if(this.props.prefillData?.eoi_data?.benefit_coverage_info?.eoi_info?.duplicateSSN === false ){
        //     this.setState({isDuplicate: true})
        // }
        // }
        if (trustedData.authenticated) {
            const coverages = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {})
            let healthAuthData = _.get(this.props, "prefillData.eoi_data.health_authorization", {});
            let employee_mib = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.mib_auth", false);
            let employee_electronic = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.electronic_consent", false);
            let spouse_mib = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.mib_auth", false)
            let spouse_electronic = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.electronic_consent", false)
            let emp_name = this.state.empName;
            let spouse_name = this.state.spouseName;
            let child_names = this.state.childName;
            let emp_data = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
            let spouse_data = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {});
            let child_info = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
            let childState = _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state");
            let child_Eligible = this.state.child_Eligible;
            let child_Eligibles = []
            let childElectionsList = [];
            let eligibleChild = [];
            let employeeCoveredStatus = false;
            child_info && child_info.map((child) => {
                let currentAge = this.state.date - parseInt(child.dob);
                let childMiddle = child.middle_initial !== null ? child.middle_initial : "";
                if (!this.state.childEligible) {
                    if (currentAge >= 18) {
                        child_Eligible = true;
                        child_Eligibles.push(true);
                        child_names.push(child.first_name + " " + childMiddle + " " + child.last_name);
                        eligibleChild.push(child);
                        child_available = true;
                    } else if (currentAge >= 15 && childState === "NC") {
                        child_Eligible = true;
                        child_Eligibles.push(true);
                        child_names.push(child.first_name + " " + childMiddle + " " + child.last_name);
                        eligibleChild.push(child);
                        child_available = true;
                    } else {
                        child_Eligible = false;
                        member_available = true;
                       // employeeCoveredStatus = true;
                        child_Eligibles.push(false);
                    }
                }
                return this.state.date - currentAge
            })
            if (eligibleChild.length > 0) {
                for (let k = 0; k < eligibleChild.length; k++) {
                    let childElection = {};
                    childElection.child_mib_auth = (eligibleChild[k].mib_auth, false);
                    childElection.child_electronic_consent = (eligibleChild[k].child_electronic_consent, false);
                    childElection.showMibChildError = false;
                    childElection.showAgreeChildError = false;
                    childElection.child_name = eligibleChild[k].first_name
                    childElectionsList.push(childElection);
                }
            }
            if (emp_data.mib_auth !== undefined) {
                health_auth_data = healthAuthData;
            }

            if (emp_data && emp_data.first_name !== undefined) {
                member_available = true;
                emp_name = emp_data.first_name + " " + (emp_data.middle_initial ? (emp_data.middle_initial + " ") : "") + emp_data.last_name;
                //emp_name = emp_data.first_name + " " + emp_data.middle_initial + " " + emp_data.last_name;
            }
            if (spouse_data && spouse_data.first_name !== undefined) {
                spouse_name = spouse_data.first_name + " " + (spouse_data.middle_initial ? (spouse_data.middle_initial + " ") : "") + spouse_data.last_name;
                //spouse_name = spouse_data.first_name + " " + spouse_data.middle_initial + " " + spouse_data.last_name;
                spouse_available = true;
            }
            const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability"]
            let coverageElected = []
            let allEmployeeStatus = []
            let allSpouseStatus = []
            let allChildrenStatus = []
            for (const coverage in coverages) {
                let disabilityCoverageElectedStatus = false
                let spouseCoveredStatus = false
                let childCoveredStatus = false
                if (disabilityCoverages.includes(coverage)) {
                    disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
                }
                else {
                    if (!employeeCoveredStatus) {
                        employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
                    }
                    spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
                    childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered
                }

                if (employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus === true || disabilityCoverageElectedStatus === true) {
                    coverageElected.push(coverage)
                }
                allEmployeeStatus.push(employeeCoveredStatus)
                allEmployeeStatus.push(disabilityCoverageElectedStatus)
                allSpouseStatus.push(spouseCoveredStatus)
                allChildrenStatus.push(childCoveredStatus)
            }
            this.setState({
                empName: emp_name, spouseName: spouse_name, childName: child_names,
                trustedUser: trustedData.authenticated, health_authorization: health_auth_data,
                displayMember: allEmployeeStatus.includes(true),
                displaySpouse: allSpouseStatus.includes(true),
                displayChild: allChildrenStatus.includes(true),
                employee_mib_auth: employee_mib,
                employee_electronic_consent: employee_electronic,
                spouse_mib_auth: spouse_mib,
                spouse_electronic_consent: spouse_electronic,
                childEligible: child_Eligible,
                child: childElectionsList,
                downloadPdfPageLink: '/grpeoia/eoi/trustedPrintedEOI'
            });

        }
        else {
            let dropdown = _.get(this.props, "whoCovered");
            let healthAuthData = _.get(this.props, "prefillData.eoi_data.health_authorization", {});
            let employee_mib = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.mib_auth", false);
            let employee_electronic = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.electronic_consent", false);
            let spouse_mib = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.mib_auth", false)
            let spouse_electronic = _.get(this.props, "eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.electronic_consent", false)
            if (employee_mib !== undefined) {
                health_auth_data = healthAuthData;
            }
            let emp_name = this.state.empName;
            let spouse_name = this.state.spouseName;
            let child_names = this.state.childName;
            let child_Eligible = this.state.childEligible;
            let emp_data = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
            let spouse_data = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {});
            let child_info = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
            let childState = _.get(this.props, "nonTrustedData.eoi_data.group_info.group_situs_state");
            let eligibleChild = [];
            let child_Eligibles = [];
            let childElectionsList = [];

            child_info && child_info.map((child) => {
                let currentAge = this.state.date - parseInt(child.dob);
                if (!this.state.childEligible) {
                    if (currentAge >= 18) {
                        child_Eligible = true;
                        child_Eligibles.push(true);
                        child_names.push(child.first_name + " " + child.middle_initial + " " + child.last_name);
                        eligibleChild.push(child);
                        child_available = true;
                    } else if (currentAge >= 15 && childState.includes("NC")) {
                        child_Eligible = true;
                        child_Eligibles.push(true);
                        child_names.push(child.first_name + " " + child.middle_initial + " " + child.last_name);
                        eligibleChild.push(child);
                        child_available = true;
                    } else {
                        child_Eligible = false;
                        this.setState({ displayMember: true })
                        child_Eligibles.push(false);
                    }
                }
                return this.state.date - currentAge
            })
            if (eligibleChild.length > 0) {
                for (let k = 0; k < eligibleChild.length; k++) {
                    let childElection = {};
                    childElection.child_mib_auth = (eligibleChild[k].mib_auth, false);
                    childElection.child_electronic_consent = (eligibleChild[k].child_electronic_consent, false);
                    childElection.showMibChildError = false;
                    childElection.showAgreeChildError = false;
                    childElection.child_name = eligibleChild[k].first_name
                    childElectionsList.push(childElection);
                }
            }
            if (emp_data.first_name !== undefined) {
                //let emp_data_mi = emp_data.middle_initial !== undefined ? emp_data.middle_initial : " ";
                // (memberdata.middle_initial ? (memberdata.middle_initial + " ") : "")
                emp_name = emp_data.first_name + " " + (emp_data.middle_initial ? (emp_data.middle_initial + " ") : "") + emp_data.last_name;
                member_available = true
            }
            if (spouse_data.first_name !== undefined) {
                spouse_name = spouse_data.first_name + " " + (spouse_data.middle_initial ? (spouse_data.middle_initial + " ") : "") + spouse_data.last_name;
                spouse_available = true;
            }

            if (dropdown === "Me") {
                spouse_available = false;
                child_available = false;
            }
            if (dropdown === "My child(ren)") {
                member_available = false;
                spouse_available = false;
            }
            if (dropdown === "My spouse") {
                member_available = false;
                child_available = false;
            }
            if (dropdown === "My spouse, my child(ren)") {
                member_available = false;
            }
            if (dropdown === "My spouse and child") {
                member_available = false;
            }
            if (dropdown === "Me, my child(ren)") {
                spouse_available = false;
            }
            if (dropdown === "Me, my spouse") {
                child_available = false;
            }
            if (dropdown === "My child") {
                member_available = false;
                spouse_available = false;
            }
            this.setState({
                empName: emp_name, spouseName: spouse_name, childName: child_names, trustedUser: trustedData.authenticated, health_authorization: health_auth_data,
                displayMember: member_available, displaySpouse: spouse_available, displayChild: child_available,
                downloadPdfPageLink: '/grpeoiua/eoi/nonTrustedPrintedEOI',
                employee_mib_auth: employee_mib,
                employee_electronic_consent: employee_electronic,
                spouse_mib_auth: spouse_mib,
                spouse_electronic_consent: spouse_electronic,
                childEligible: child_Eligible,
                child: childElectionsList,
            });
        }

    }


    componentDidUpdate(prevProps, prevState) {

        if (this.props.prefillData?.eoi_data?.benefit_coverage_info?.eoi_info?.duplicateSSN !== prevProps.prefillData?.eoi_data?.benefit_coverage_info?.eoi_info?.duplicateSSN) {
            debugger;
            if (this.props.prefillData?.eoi_data?.benefit_coverage_info?.eoi_info?.duplicateSSN === true) {
                if (this.props.isTrusted === true) {
                    this.setState({ isDuplicate: false })
                }
                else {
                    this.setState({ isDuplicate: true })
                }
            }

        }
    }

    handleAccept = () => {
        this.props.history.push(`/eoi/homePage`)
    }

    handleOptOut = () => {
        this.props.history.push(`/eoi/trustedPrintedEOI`)
    }

    handleClose = () => {
        this.setState({
            bannerFlag: false
        });
    }

    handleCheckBoxChange = (e, Ind, index) => {
        const childElections = [...this.state.child];
        let health_auth = this.state.health_authorization;
        let employee_mib = this.state.employee_mib_auth;
        let spouse_mib = this.state.spouse_mib_auth;
        let employee_electronic = this.state.employee_electronic_consent;
        let spouse_electronic = this.state.spouse_electronic_consent;
        if (Ind === "emp") {
            if (this.state.showEmpMibErr === true) {
                this.setState({
                    showEmpMibErr: false
                })
            }
            employee_mib = !employee_mib;
        }
        if (Ind === "spouse") {
            if (this.state.showSpouseMibErr === true) {
                this.setState({
                    showSpouseMibErr: false
                })
            }
            spouse_mib = !spouse_mib;
        }
        if (Ind === "child") {
            const child_election = childElections[index];
            const childArray = child_election;
            let filteredChildObj = childArray;
            if (filteredChildObj.showMibChildError === true) {
                childElections[index].showMibChildError = false;
            }
            childElections[index].child_mib_auth = !childElections[index].child_mib_auth
        }
        if (Ind === "empEC") {
            if (this.state.showEmpAgreeErr === true) {
                this.setState({
                    showEmpAgreeErr: false
                })
            }
            employee_electronic = !employee_electronic;
        }
        if (Ind === "spouseEC") {
            if (this.state.showSpouseAgreeErr === true) {
                this.setState({
                    showSpouseAgreeErr: false
                })
            }
            spouse_electronic = !spouse_electronic;
        }
        if (Ind === "childEC") {
            const child_election = childElections[index];
            const childArray = child_election;
            let filteredChildObj = childArray;
            if (filteredChildObj.showAgreeChildError === true) {
                childElections[index].showAgreeChildError = false;
            }
            childElections[index].child_electronic_consent = !childElections[index].child_electronic_consent;
        }
        if (this.state.displayMember && this.state.displaySpouse && this.state.displayChild && employee_electronic === true && spouse_electronic === true && health_auth.child_electronic_consent === true) {
            this.setState({
                bannerFlag: false
            })

        }
        else if (this.state.displayMember && employee_electronic === true) {
            this.setState({
                bannerFlag: false
            })
        }
        else if (this.state.displaySpouse && spouse_electronic === true) {
            this.setState({
                bannerFlag: false
            })
        } else if (this.state.displayChild) {
            for (let k = 0; k < childElections.length; k++) {
                if (childElections[k].child_electronic_consent) {
                    this.setState({
                        bannerFlag: false
                    })
                }
            }
        }
        this.setState({
            health_authorization: health_auth, employee_mib_auth: employee_mib,
            employee_electronic_consent: employee_electronic,
            spouse_mib_auth: spouse_mib, spouse_electronic_consent: spouse_electronic
        })

    }

    downloadPdf = (e, ind) => {

        let fileURL = '';
        if (ind === "optout") {
            fileURL = this.state.health_authorization.form_optout_link;
        }
        if (ind === "formAuth") {
            fileURL = this.state.health_authorization.form_authorization_link;
        }
        if (ind === "consent") {
            fileURL = this.state.health_authorization.form_consent_link;
        }

        fetch(fileURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            // .then((blob) => {
            //     // Create blob link to download
            //     const url = window.URL.createObjectURL(
            //         new Blob([blob]),
            //     );
            //     const link = document.createElement('a');
            //     link.href = url;
            //     link.setAttribute(
            //         'download',
            //         `EoiPrintedForm.pdf`,
            //     );

            //     // Append to html link element page
            //     document.body.appendChild(link);

            //     // Start download
            //     link.click();

            // });
            .then((blob) => {
                const url = new Blob([blob], { type: 'application/pdf' });
                const fileurl = URL.createObjectURL(url);
                var newwin = window.open(fileurl);
                setTimeout(() => {
                    newwin.document.title = "EoiPrintedForm.pdf";
                }, 300);
            });
        e.preventDefault();
    }

    handleDuplicate = () => {
        this.setState({ isDuplicate: false });
    }

    handleContinue = () => {
        let showError = false;
        let showBanner = this.state.bannerFlag;
        let showMibEmpError = this.state.showEmpMibErr
        let showAgreeEmpError = this.state.showEmpAgreeErr;
        let showMibSpouseError = this.state.showSpouseMibErr;
        let showAgreeSpouseError = this.state.showSpouseAgreeErr;
        let memberDisplay = this.state.displayMember;
        let spouseDisplay = this.state.displaySpouse;
        let childDisplay = this.state.displayChild;
        let employee_mib = this.state.employee_mib_auth;
        let spouse_mib = this.state.spouse_mib_auth;
        let employee_electronic = this.state.employee_electronic_consent;
        let spouse_electronic = this.state.spouse_electronic_consent;
        let childElections = [...this.state.child];

        if (memberDisplay && !employee_mib) {
            showError = true;
            showMibEmpError = true;
            scrollUp()
        }
        if (spouseDisplay && !spouse_mib) {
            showError = true;
            showMibSpouseError = true;
            scrollUp()
        }
        if (childDisplay) {
            for (let k = 0; k < childElections.length; k++) {
                if (!childElections[k].child_mib_auth) {
                    childElections[k].showMibChildError = true;
                    showError = true;
                    scrollUp()
                }
            }
        }
        // if (memberDisplay && spouseDisplay && childDisplay) {
        //     if (!employee_electronic) {
        //         showError = true;
        //         showBanner = true
        //     }
        //     if (!spouse_electronic) {
        //         showError = true;
        //         showBanner = true
        //     }
        //     for (let k = 0; k < childElections.length; k++) {
        //         if (!childElections[k].child_electronic_consent) {
        //             showError = true;
        //             showBanner = true
        //         }
        //     }
        // }
        // else 
        if (memberDisplay && employee_electronic !== true) {
            showAgreeEmpError = true;
            showError = true;
            showBanner = true;
            scrollUp();
        }
        if (spouseDisplay && spouse_electronic !== true) {
            showAgreeSpouseError = true;
            showError = true;
            showBanner = true;
            scrollUp();
        }
        if (childDisplay) {
            for (let k = 0; k < childElections.length; k++) {
                if (!childElections[k].child_electronic_consent) {
                    childElections[k].showAgreeChildError = true;
                    showError = true;
                    showBanner = true;
                    scrollUp();
                }
            }
        }


        this.setState({ bannerFlag: showBanner, showEmpMibErr: showMibEmpError, showSpouseMibErr: showMibSpouseError, child: childElections, showEmpAgreeErr: showAgreeEmpError, showSpouseAgreeErr: showAgreeSpouseError })

        if (!showError) {
            if (this.state.trustedUser) {
                let child = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
                _.set(this.props, "prefillData.eoi_data.health_authorization", this.state.health_authorization);
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.mib_auth", this.state.employee_mib_auth);
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.mib_auth", this.state.spouse_mib_auth);
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.electronic_consent", this.state.employee_electronic_consent);
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.electronic_consent", this.state.spouse_electronic_consent);
                for (let k = 0; k < this.state.child.length; k++) {
                    let objIndex = child.findIndex((obj => obj.first_name === this.state.child[k].child_name));
                    _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + objIndex + `].mib_auth`, this.state.child[k].child_mib_auth);
                    _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + objIndex + `].electronic_consent`, this.state.child[k].child_electronic_consent);
                }

                // this.props.submitMib(_.get(this.props, "prefillData", {}),'/eoi/homePage');
                this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""), '/eoi/homePage')
                //this.props.history.push(`/eoi/homePage`);
            }
            else {
                let child = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
                _.set(this.props, "nonTrustedData.eoi_data.health_authorization", this.state.health_authorization);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.mib_auth", this.state.employee_mib_auth);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.mib_auth", this.state.spouse_mib_auth);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.electronic_consent", this.state.employee_electronic_consent);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.electronic_consent", this.state.spouse_electronic_consent);

                // console.log(_.get(this.props, "nonTrustedData.eoi_data.case_id") ,  _.get(this.props, "dataRestoration"))
                for (let k = 0; k < this.state.child.length; k++) {
                    let objIndex = child.findIndex((obj => obj.first_name === this.state.child[k].child_name));
                    _.set(this.props, `nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + objIndex + `].mib_auth`, this.state.child[k].child_mib_auth);
                    _.set(this.props, `nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + objIndex + `].electronic_consent`, this.state.child[k].child_electronic_consent);
                }
                if (!_.get(this.props, "nonTrustedData.eoi_data.case_id") && _.get(this.props, "dataRestoration.eoi_data")) {
                    console.log(_.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information", {}),
                        _.get(this.props, "dataRestoration.eoi_data.benefit_coverage_info.eoi_info.personal_information", {}),
                        _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {}),
                        _.get(this.props, "dataRestoration.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {})
                    )
                    console.log(compareNestedObject(_.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information", {}),
                        _.get(this.props, "dataRestoration.eoi_data.benefit_coverage_info.eoi_info.personal_information", {})))
                    console.log(compareNestedObject(_.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {}),
                        _.get(this.props, "dataRestoration.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {})))

                    let personal_info = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information", {})
                    let restore_personal_info = _.get(this.props, "dataRestoration.eoi_data.benefit_coverage_info.eoi_info.personal_information", {})

                    let employeeCompareStatus = []
                    let spouseCompareStatus;
                    let childCompareStatus = [];
                    Object.keys(personal_info).forEach(key => {
                        if (key === "employee") {
                            let a = personal_info[key]
                            let b = restore_personal_info[key]
                            Object.keys(a).forEach(key => {
                                if (a[key] === b[key]) {
                                    employeeCompareStatus.push(true)
                                }
                                else {
                                    employeeCompareStatus.push(false)
                                }

                            })
                        }
                        if (key === "spouse") {
                            if (personal_info[key].first_name === restore_personal_info[key].first_name &&
                                personal_info[key].middle_initial === restore_personal_info[key].middle_initial &&
                                personal_info[key].last_name === restore_personal_info[key].last_name &&
                                personal_info[key].dob === restore_personal_info[key].dob &&
                                personal_info[key].gender === restore_personal_info[key].gender) {
                                spouseCompareStatus = true
                            }
                        }
                        if (key === "child") {
                            if (personal_info[key].length === 0 && restore_personal_info[key].length === 0) {
                                childCompareStatus.push(true)
                            }
                            else if (personal_info[key] && restore_personal_info[key] && personal_info[key].length > 0 && restore_personal_info[key].length > 0
                                && personal_info[key].length === restore_personal_info[key].length) {
                                personal_info[key] && personal_info[key].map(childP => {
                                    restore_personal_info[key] && restore_personal_info[key].map(childR => {
                                        if (childP.sequence === childR.sequence) {
                                            if (childP.first_name === childR.first_name &&
                                                childP.middle_initial === childR.middle_initial &&
                                                childP.last_name === childR.last_name &&
                                                childP.dob === childR.dob &&
                                                childP.gender === childR.gender) {
                                                childCompareStatus.push(true)

                                            }
                                            else {
                                                childCompareStatus.push(false)
                                            }
                                        }
                                        return childR
                                    })
                                    return childP
                                })
                            }
                            else {
                                childCompareStatus.push(false)
                            }
                        }
                    })
                    const cvg_elec = JSON.parse(JSON.stringify(_.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {})))
                    let data_restore_cvg_elec = JSON.parse(JSON.stringify(_.get(this.props, "dataRestoration.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {})))
                    let disabilityCompareStatus = [];
                    let coverageCompareStatus = [];
                    Object.keys(cvg_elec).forEach(key => {
                        if (key === "short_term_disability" || key === "long_term_disability" || key === "voluntary_long_term_disability") {
                            if (cvg_elec[key].elected === data_restore_cvg_elec[key].elected &&
                                cvg_elec[key].excess_amount === data_restore_cvg_elec[key].excess_amount &&
                                cvg_elec[key].insured_amount === data_restore_cvg_elec[key].insured_amount) {
                                disabilityCompareStatus.push(true)
                            }
                            else {
                                disabilityCompareStatus.push(false)
                            }
                        }
                        else {
                            if (cvg_elec[key].employee_covered === data_restore_cvg_elec[key].employee_covered &&
                                cvg_elec[key].employee_excess_amount === data_restore_cvg_elec[key].employee_excess_amount &&
                                cvg_elec[key].employee_insured_amount === data_restore_cvg_elec[key].employee_insured_amount &&
                                cvg_elec[key].spouse_covered === data_restore_cvg_elec[key].spouse_covered &&
                                cvg_elec[key].spouse_excess_amount === data_restore_cvg_elec[key].spouse_excess_amount &&
                                cvg_elec[key].spouse_insured_amount === data_restore_cvg_elec[key].spouse_insured_amount &&
                                cvg_elec[key].child_covered === data_restore_cvg_elec[key].child_covered &&
                                cvg_elec[key].child_excess_amount === data_restore_cvg_elec[key].child_excess_amount &&
                                cvg_elec[key].child_insured_amount === data_restore_cvg_elec[key].child_insured_amount) {
                                coverageCompareStatus.push(true)
                            }
                            else {
                                coverageCompareStatus.push(false)
                            }
                        }

                    })

                    if (employeeCompareStatus.every(data => data === true) && spouseCompareStatus === true && childCompareStatus.every(data => data === true) &&
                        disabilityCompareStatus.every(data => data === true) && coverageCompareStatus.every(data => data === true)) {
                        _.set(this.props, "nonTrustedData.eoi_data.create_new_case_id", false);
                    }
                    else {
                        _.set(this.props, "nonTrustedData.eoi_data.create_new_case_id", true);
                    }

                }
                this.props.submitMib(_.get(this.props, "nonTrustedData", {}), '/eoi/homePage');
            }

        }
    }

    render() {
        // console.log(this.props.groupInfoResponse)
        //console.log(this.state.health_authorization)
        //console.log("emp",this.state.employeeCovered)
        //console.log("spouse",this.state.spouseCovered)
        // console.log(this.state.displayChild,"child",this.state.displayMember)
        return (
            <>
                {this.state.isDuplicate === true ? <DuplicatePopup handleDuplicate={() => this.handleDuplicate()} isTrusted={(_.get(this.props, "trustedFlag", ""))} /> : null}
                {this.state.bannerFlag === true ?
                    // <AlertBanner
                    //     errorMessage="You must agree to conduct business electronically to complete your EOI online. If you opt-out, you can fill out the PDF form instead."
                    //     handleClose={() => this.handleClose()} />
                    <div className="banner-margin showbanner">
                        <div role="alert" className="alert-banner warning">
                            <button aria-label='Alert Banner Close' className="close hidden-text" onClick={() => this.handleClose()}
                            >
                                <i className="fas fa-times"> <span>Close</span> </i>
                            </button>

                            <span className="alert-msg"><i className="fas fa-exclamation-triangle" aria-label="error" role="img"></i>You must agree to conduct business electronically to complete your EOI online.
                                If you opt-out, you can fill out the <a onClick={(e) => this.onOptout(e)} href="/#">PDF form</a> instead.</span>

                        </div>
                    </div>
                    : null}

                <main>
                    <div className="container checkbox-para">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    <h1 class="screen-reader" style={{ fontSize: '16px' }}>Authorization Page</h1>
                                    <h2 style={{ fontSize: '32px' }}>Authorization to obtain information</h2>
                                    <p>We need permission from the following individuals to collect medical and other non-medical information from third parties.
                                    </p>
                                    <p><strong>Important:</strong> Each individual named below must personally sign and agree.
                                    </p>
                                </div>
                                {this.state.displayMember ?
                                    <div className="form-group">
                                        <h3 style={{ fontSize: '30px' }} className="mt-5 pb-3">{this.state.empName}</h3>
                                        <div className="checkbox">
                                            <input type="checkbox" 
                                            id="checks_1" 
                                            aria-describedby="showEmpMibErr-error" 
                                            checked={this.state.employee_mib_auth} 
                                            onChange={(e) => this.handleCheckBoxChange(e, "emp")} />
                                            {/* {console.log(this.state.employee_mib_auth, "MIB")} */}
                                            <label htmlFor="checks_1">
                                                By checking this box, I, {this.state.empName}, am electronically signing and agreeing to the &nbsp;
                                                {/* <a onClick={(e) => this.downloadPdf(e,"formAuth")} href="/#">Authorization to Obtain and Release Medical Information</a> */}
                                                <a href={_.get(this.props, "prefillData.eoi_data.health_authorization.form_authorization_link", "")} target="_blank" rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a>
                                            </label>

                                        </div>
                                        <div className="form-group">
                                            {this.state.showEmpMibErr ? <span id="showEmpMibErr-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>You must sign the authorization to obtain and release medical information to proceed.</span> : null}
                                        </div>
                                    </div>
                                    :
                                    null}
                                {this.state.displaySpouse ?
                                    <div className="form-group">
                                        <h3 style={{ fontSize: '2rem' }}>{this.state.spouseName !== "" ? this.state.spouseName : "My Spouse"}</h3>
                                        <div className="checkbox">
                                            <input type="checkbox" 
                                            id="checks_2" 
                                            aria-describedby="showSpouseMibErr-error" 
                                            checked={this.state.spouse_mib_auth} 
                                            onChange={(e) => this.handleCheckBoxChange(e, "spouse")} />
                                            {this.state.spouseName !== "" ?
                                                <label htmlFor="checks_2">
                                                    By checking this box, I, {this.state.spouseName}, am electronically signing and agreeing to the &nbsp;
                                                    {/* <a onClick={(e) => this.downloadPdf(e,"formAuth")} href="/#">Authorization to Obtain and Release Medical Information</a> */}
                                                    {/* <a href={this.state.health_authorization.form_authorization_link} target="_blank"  rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a> */}
                                                    <a href={_.get(this.props, "prefillData.eoi_data.health_authorization.form_authorization_link", "")} target="_blank" rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a>
                                                </label> :
                                                <label htmlFor="checks_2">
                                                    By checking this box, My Spouse is electronically signing and agreeing to the &nbsp;
                                                    {/* <a onClick={(e) => this.downloadPdf(e,"consent")} href="/#">Agreement to Conduct Business Electronically</a> */}
                                                    <a href={_.get(this.props, "prefillData.eoi_data.health_authorization.form_authorization_link", "")} target="_blank" rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a>
                                                    {/* {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "OR" ?
                                               <a href="https://qa.guardiananytime.com/gafd/wps/wcm/connect/36774c1e-f0f7-4bb9-95e2-a7dacb7fa186/OR+Agreement+to+Conduct+Business+Electronically.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-36774c1e-f0f7-4bb9-95e2-a7dacb7fa186-nXF4HZa"
                                                   target="_blank" rel="noopener noreferrer" >Authorization to Obtain and Release Medical Information</a>
                                               : <a href={this.state.health_authorization.form_authorization_link} target="_blank"  rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a>
                                           }                                             */}
                                                </label>}
                                        </div>
                                        <div className="form-group">
                                            {this.state.showSpouseMibErr ? <span id="showSpouseMibErr-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>You must sign the authorization to obtain and release medical information to proceed.</span> : null}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {this.state.displayChild ?
                                    <div>
                                        {this.state.childName.map((child, index) => {

                                            return (
                                                <div className="form-group">
                                                    <h3 style={{ fontSize: '30px' }}>{child}</h3>
                                                    <div className="checkbox">
                                                        <input 
                                                        key={index} 
                                                        type="checkbox" 
                                                        id={"checks_" + (3 + index)} 
                                                        aria-describedby="showMibChildError-error" 
                                                        checked={this.state.child[index].child_mib_auth} 
                                                        onChange={(e) => this.handleCheckBoxChange(e, "child", index)} />
                                                        {child !== "" ?
                                                            <label htmlFor={"checks_" + (3 + index)}>
                                                                By checking this box, I, {child}, am electronically signing and agreeing to the &nbsp;
                                                                {/* <a onClick={(e) => this.downloadPdf(e,"formAuth")} href="/#">Authorization to Obtain and Release Medical Information</a> */}
                                                                {/* <a href={this.state.health_authorization.form_authorization_link} target="_blank" rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a> */}
                                                                <a href={_.get(this.props, "prefillData.eoi_data.health_authorization.form_authorization_link", "")} target="_blank" rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a>
                                                            </label> :
                                                            <label htmlFor={"checks_" + (3 + index)}>
                                                                By checking this box, My Child is electronically signing and agreeing to the &nbsp;
                                                                {/* <a onClick={(e) => this.downloadPdf(e,"formAuth")} href="/#">Authorization to Obtain and Release Medical Information</a> */}
                                                                {/* <a href={this.state.health_authorization.form_authorization_link} target="_blank" rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a> */}
                                                                <a href={_.get(this.props, "prefillData.eoi_data.health_authorization.form_authorization_link", "")} target="_blank" rel="noopener noreferrer">Authorization to Obtain and Release Medical Information</a>
                                                            </label>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        {this.state.child[index].showMibChildError ? <span id="showMibChildError-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>You must sign the authorization to obtain and release medical information to proceed.</span> : null}
                                                    </div>
                                                </div>)
                                        })}</div> : null}
                                <div className="intro pt-5">
                                    <h2 style={{ fontSize: '32px' }}>Agreement to conduct business electronically</h2>
                                    <p>If you choose to opt-out of conducting business electronically, you can complete a <a onClick={(e) => this.onOptout(e)} href="/#">PDF version of the form.</a>
                                    </p>
                                </div>
                                {this.state.displayMember ?
                                    <div className="form-group">
                                        <h3 style={{ fontSize: '30px' }}>{this.state.empName}</h3>
                                        <div className="checkbox">
                                            <input 
                                            type="checkbox" 
                                            id={"checks_" + (this.state.childName.length + 3)} 
                                            aria-describedby="showEmpAgreeErr-error"
                                            checked={this.state.employee_electronic_consent} 
                                            onChange={(e) => this.handleCheckBoxChange(e, "empEC")} />
                                            <label htmlFor={"checks_" + (this.state.childName.length + 3)}>
                                                By checking this box, I, {this.state.empName}, am electronically signing and agreeing to the &nbsp;
                                                {/* <a onClick={(e) => this.downloadPdf(e,"consent")} href="/#">Agreement to Conduct Business Electronically</a> */}
                                                {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "OR" ?
                                                    <a href="https://qa.guardiananytime.com/gafd/wps/wcm/connect/36774c1e-f0f7-4bb9-95e2-a7dacb7fa186/OR+Agreement+to+Conduct+Business+Electronically.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-36774c1e-f0f7-4bb9-95e2-a7dacb7fa186-nXF4HZa"
                                                        target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically</a>
                                                    : <a href="https://www.guardianlife.com/agreement-conduct-business-electronically"
                                                        target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically</a>}
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            {this.state.showEmpAgreeErr ? <span id="showEmpAgreeErr-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>You must sign the agreement to conduct business electronically to proceed.</span> : null}
                                        </div>
                                    </div> : null}
                                {this.state.displaySpouse ?
                                    <div className="form-group">
                                        <h3 style={{ fontSize: '30px' }}>{this.state.spouseName !== "" ? this.state.spouseName : "My Spouse"}</h3>
                                        <div className="checkbox">
                                            <input 
                                            type="checkbox" 
                                            id={"checks_" + (this.state.childName.length + 4)} 
                                            aria-describedby="showSpouseAgreeErr-error"
                                            checked={this.state.spouse_electronic_consent} 
                                            onChange={(e) => this.handleCheckBoxChange(e, "spouseEC")} />
                                            {this.state.spouseName !== "" ?
                                                <label htmlFor={"checks_" + (this.state.childName.length + 4)}>
                                                    By checking this box, I, {this.state.spouseName}, am electronically signing and agreeing to the &nbsp;
                                                    {/* <a onClick={(e) => this.downloadPdf(e,"consent")} href="/#">Agreement to Conduct Business Electronically</a> */}
                                                    {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "OR" ?
                                                        <a href="https://qa.guardiananytime.com/gafd/wps/wcm/connect/36774c1e-f0f7-4bb9-95e2-a7dacb7fa186/OR+Agreement+to+Conduct+Business+Electronically.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-36774c1e-f0f7-4bb9-95e2-a7dacb7fa186-nXF4HZa"
                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically</a>
                                                        : <a href="https://www.guardianlife.com/agreement-conduct-business-electronically"
                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically</a>}
                                                </label> :
                                                <label htmlFor={"checks_" + (this.state.childName.length + 4)}>
                                                    By checking this box, My Spouse is electronically signing and agreeing to the &nbsp;
                                                    {/* <a onClick={(e) => this.downloadPdf(e,"consent")} href="/#">Agreement to Conduct Business Electronically</a> */}
                                                    {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "OR" ?
                                                        <a href="https://qa.guardiananytime.com/gafd/wps/wcm/connect/36774c1e-f0f7-4bb9-95e2-a7dacb7fa186/OR+Agreement+to+Conduct+Business+Electronically.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-36774c1e-f0f7-4bb9-95e2-a7dacb7fa186-nXF4HZa"
                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically </a>
                                                        : <a href="https://www.guardianlife.com/agreement-conduct-business-electronically"
                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically</a>}
                                                </label>}
                                        </div>
                                        <div className="form-group">
                                            {this.state.showSpouseAgreeErr ? <span id="showSpouseAgreeErr-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>You must sign the agreement to conduct business electronically to proceed.</span> : null}
                                        </div>
                                    </div> : null}

                                {this.state.displayChild ?
                                    <div>
                                        {this.state.childName.map((child, index) => {
                                            return (
                                                <div>
                                                    <div className="form-group">

                                                        <h3 style={{ fontSize: '30px' }}>{child}</h3>
                                                        <div className="checkbox">
                                                            <input 
                                                            key={index} 
                                                            type="checkbox" 
                                                            id={"checks_" + (this.state.childName.length + 5 + index)} 
                                                            aria-describedby="showAgreeChildError-error"
                                                            checked={this.state.child[index].child_electronic_consent} 
                                                            onChange={(e) => this.handleCheckBoxChange(e, "childEC", index)} />
                                                            {child !== "" ?
                                                                <label htmlFor={"checks_" + (this.state.childName.length + 5 + index)}>
                                                                    By checking this box, I, {child}, am electronically signing and agreeing to the &nbsp;
                                                                    {/* <a onClick={(e) => this.downloadPdf(e,"consent")} href="/#">Agreement to Conduct Business Electronically</a> */}
                                                                    {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "OR" ?
                                                                        <a href="https://qa.guardiananytime.com/gafd/wps/wcm/connect/36774c1e-f0f7-4bb9-95e2-a7dacb7fa186/OR+Agreement+to+Conduct+Business+Electronically.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-36774c1e-f0f7-4bb9-95e2-a7dacb7fa186-nXF4HZa"
                                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically </a>
                                                                        : <a href="https://www.guardianlife.com/agreement-conduct-business-electronically"
                                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically</a>}                                                             </label>
                                                                : <label htmlFor={"checks_" + (this.state.childName.length + 5 + index)}>
                                                                    By checking this box, My child is electronically signing and agreeing to the &nbsp;
                                                                    {/* <a onClick={(e) => this.downloadPdf(e,"consent")} href="/#">Agreement to Conduct Business Electronically</a> */}
                                                                    {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "OR" ?
                                                                        <a href="https://qa.guardiananytime.com/gafd/wps/wcm/connect/36774c1e-f0f7-4bb9-95e2-a7dacb7fa186/OR+Agreement+to+Conduct+Business+Electronically.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-36774c1e-f0f7-4bb9-95e2-a7dacb7fa186-nXF4HZa"
                                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically </a>
                                                                        : <a href="https://www.guardianlife.com/agreement-conduct-business-electronically"
                                                                            target="_blank" rel="noopener noreferrer" >Agreement to Conduct Business Electronically</a>}                                                             </label>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            {this.state.child[index].showAgreeChildError ? <span id="showAgreeChildError-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>You must sign the agreement to conduct business electronically to proceed.</span> : null}
                                                        </div>
                                                    </div>
                                                </div>)
                                        })} </div> : null}
                                <hr />
                                <div className="field-group float-right">
                                    <button className="btn btn-primary" onClick={this.handleContinue}>Continue</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </main>
            </>
        );
    }
}



//export default Mib;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(Mib);
