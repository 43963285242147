import React, { Component } from "react";
import _ from 'lodash';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators } from "../../store/HomePage/HomePageAction";
import { scrollUp } from "../../utils/sharedFunction";
import AlertBanner from "../../components/AlertBanner/AlertBanner";

class CAConstent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empName: "",
            name: '',
            emailId: '',
            prefillEmail: '',
            date: '',
            situsState: 'CA',
            showError: false,
            trustedUser: false,
            displayDownnload: false,
        }
    }
    componentDidMount() {
        scrollUp();
        document.title = "Consent Form | Group EOI - Guardian";
        let emp_name, date, now, prefillEmail;
        let trustedData = _.get(this.props, "prefillData.eoi_data", {});
        now = new Date();
        date = (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        let emp_data = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
        if (emp_data.email_address === null) {
            prefillEmail = emp_data.email_address;
        } else {
            prefillEmail = emp_data.email_address;
            this.setState({ emailRequired: '', emailInvalid: '' })
        }
        if (emp_data && emp_data.first_name !== undefined) {
            emp_name = emp_data.first_name + " " + (emp_data.middle_initial ? (emp_data.middle_initial + " ") : "") + emp_data.last_name;
        }
        // let decodeURL = new URLSearchParams(this.props.location.search);
        // let perfillId = decodeURL.get('p');
        // let updateId = decodeURL.get('u');
        // let activeToken = decodeURL.get('at');
        // let browserUrlv2 = window.location.href;
        // if (_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY") {
        //     this.props.getprefillInfov2(perfillId, updateId, activeToken);
        // } else if (browserUrlv2.includes(trustedJunction) || browserUrlv2.includes(workDayJunction) || (browserUrlv2.includes(nonTrustedJunction) && perfillId && updateId)) {
        //     this.props.getprefillInfov2(perfillId, updateId, activeToken);
        // }
        this.setState({ empName: emp_name, trustedUser: trustedData.authenticated, date, prefillEmail });

    }

    onHandleChange = (e, label) => {
        if (label === "email") {
            this.setState({ emailId: e.target.value })
        }
        if (label === "name") {
            this.setState({ name: e.target.value });
        }

    }

    handleClose = () => {
        this.setState({ showError: false })
        // document.getElementsByClassName('showbanner')[0].style.display = 'none';
    }
    handleSubmit = () => {
        this.setState({
            nameRequired: '',
            nameInvalid: '',
            emailRequired: '',
            emailInvalid: '',
            // isEmailValid: ''
        })
        let showError = this.state.showError;
        let displayDownnload = this.state.displayDownnload;
        showError = false;
        let emailLength = _.get(this.state, "emailId", "").trim().length;
        console.log(emailLength, "kkemail", this.state.prefillEmail)
        let nameLength = _.get(this.state, "name", "").trim().length;
        //name validation
        if (nameLength === 0) {
            this.setState({ nameRequired: "Name is required", showError: true });
            showError=true;
            scrollUp();
        } 
        else if (nameLength>0){
        const re = /^[A-Za-z\s]+$/
        if(!re.test(_.get(this.state, "name", "").trim())){
            this.setState({nameInvalid: "Name is Invalid", showError: true }); 
            showError=true;}
        }
        //email validation
        // if (_.get(this.props, "isEmailValid", "") !== true) {
        //     showError = true;
        // }
        if (emailLength === 0 && ( this.state.prefillEmail === '' || this.state.prefillEmail === null )) {
            this.setState({ "emailRequired": "Email address is required", showError: true });
            showError = true;
            scrollUp();
        } else if (emailLength > 0) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(_.get(this.state, "emailId", "").trim())) {
                this.setState({ "emailInvalid": "Email format is example@domain.com", showError: true });
                showError = true;
            }
        } else {
            showError = false;            
        }
        if (!showError) {
            this.setState({ displayDownnload: !displayDownnload })
        }
    }

    handleContinue = () => {        
        if (_.get(this.props, "trustedFlag", "") === true) {
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.email_address", this.state.emailId);
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.employee_signature", this.state.name);
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ca_email_address", this.state.emailId);
            console.log(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee"), "employee");
            this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""))
            this.props.history.push({
                pathname: `/eoi/mib`
            })
        } else {
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.email_address", this.state.emailId);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.employee_signature", this.state.name);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ca_email_address", this.state.emailId);
            console.log(_.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee"), "employee");
            // this.props.updateprefillData(_.get(this.props, "nonTrustedData",""), _.get(this.props, "prefillId",""))  
            this.props.history.push({
                pathname: `/eoi/homepage`
            })
        }
    }
    

    emailValidation = () => {
        this.setState({
            emailRequired: '',
            emailInvalid: '',
        })
        let emailLength = _.get(this.state, "emailId", "").trim().length;
        if (emailLength === 0 && ( this.state.prefillEmail === '' || this.state.prefillEmail === null )) {
            this.setState({ "emailRequired": "Email address is required", showError: true });
        } else if (emailLength > 0) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(_.get(this.state, "emailId", "").trim())) {
                this.setState({ "emailInvalid": "Email format is example@domain.com", showError: true });
            }
        } else {
            this.setState({ showError: false})        
        }
    }
    nameValidation = () => {
        this.setState({
            nameRequired: '',
            nameInvalid: ''
        })
        let nameLength = _.get(this.state, "name", "").trim().length;
        if (nameLength === 0) {
            this.setState({ nameRequired: "Name is required", showError: true });
        } 
        else if (nameLength>0){
        const re = /^[A-Za-z\s]+$/
        if(!re.test(_.get(this.state, "name", "").trim())){
            this.setState({nameInvalid: "Name is Invalid", showError: true }); 
            this.setState({ showError: false}) 
            }
        }
    }
    onOptout = (e) => {
        e.preventDefault();
        let trusted = _.get(this.props, "prefillData.eoi_data", {});
        if (trusted.authenticated) {
            this.props.history.push({
                pathname: `/eoi/trustedPrintedEOI`,
                state: { "previousPage": "consent" }
            })
        }
        else {
            this.props.history.push({
                pathname: `/eoi/nonTrustedPrintedEOI`,
                state: { "previousPage": "consent" }
            })
        }
    }
    handleConsent = () => {
        console.log(this.state.prefillEmail, this.state.emailId, "kk")
        _.get(this.props, "caConsent", {});
        if (_.get(this.props, "trustedFlag", "") !== true) {
            _.set(this.props, "caConsent.emailId", this.state.emailId);
        } else {
            if (this.state.prefillEmail !== null) {
                _.set(this.props, "caConsent.emailId", this.state.prefillEmail);
            } else {
                _.set(this.props, "caConsent.emailId", this.state.emailId);
            }
        }
        _.set(this.props, "caConsent.name", this.state.name);
        _.set(this.props, "caConsent.date", this.state.date);
        _.set(this.props, "caConsent.situsState", this.state.situsState);

        this.props.caConstentData(_.get(this.props, "caConsent"));

    }
    render() {
        console.log(this.props, "download")
        // console.log("kk", this.props, this.state.showError,this.state.emailRequired,  this.state.nameRequired,this.state.nameInvalid)
        const username = this.state.emailId;
        let emailValid = _.get(this.props, "isEmailValid", true)
        return (
            <main>
                {/* {(this.state.showError === true && this.state.emailInvalid) ? <AlertBanner handleClose={() => this.handleClose()} errorMessage="Email format is example@domain.com" /> : null} */}
                {/* {(this.state.showError === true && this.state.nameInvalid) ? <AlertBanner handleClose={() => this.handleClose()} errorMessage="Name is required fields" /> : null} */}

                {(this.state.showError === true && (this.state.emailRequired || this.state.nameRequired)) ? <AlertBanner handleClose={() => this.handleClose()} errorMessage="Please fill out all required fields." /> : null}
                <div className="container " >
                <h1 class="screen-reader" style={{ fontSize: '16px' }}>Consent Form</h1>
                    {_.get(this.props, "trustedFlag", "") === true ? <h2 style={{ fontSize: '24px' }}>{this.state.empName}</h2> : null}
                    <div className="row " style={{ borderStyle: "solid", margin: "3px" }}>
                        <div className="col-lg-12">
                            <div className="intro mt-3" >
                                <h2 className="text-black mt-4 mb-4" style={{ fontSize: '16px' }}>THE GUARDIAN LIFE INSURANCE COMPANY OF AMERICA</h2>
                                <h2 className="text-black mt-4 mb-4" style={{ fontSize: '16px' }}>VOLUNTARY CONSENT TO RECEIVE THE ELECTRONIC TRANSMISSION OF DOCUMENTS </h2>

                                <p> By your consent below, you and The Guardian Life Insurance Company of America (“the Company”) agree to the terms of this Voluntary Consent to Receive the Electronic Transmission of Documents (“the Electronic Consent Agreement”).</p>

                                <p> <span style={{ textDecoration: "underline" }}> Your consent to receiving documents electronically is voluntary.</span> However, by reviewing and signing this consent form you will be agreeing to receive required documents electronically. Required documents are documents required to be sent by the Company that are permitted by state and federal law to be sent electronically including but not limited to evidence of insurability forms, underwriting determinations, policies and outlines of coverage, payment and billing related notices, claim related notifications, and legally required notifications and privacy notices required by state and federal law.</p>

                                <p>We are requesting your consent to proceed with an electronic process that includes:<span><strong> allowing the Company to display or deliver documents electronically, requesting that you access a secure portal to review and/or sign documents, requesting that you print or download documents and retaining them for your records, permitting the Company to utilize an electronic signature process to obtain your signature on documents, and authorizing and submitting a premium payment electronically, if applicable, in connection with your policy.</strong></span> </p>

                                <p><h3>Software and Hardware Standards:</h3></p>
                                <p>To access and retain information from the Company, you should:</p>
                                <ol type="1">
                                    <li>	Be able to view the disclosures on your monitor and save files to your computer or send screen prints to your printer, which can be done with your browser.</li>
                                    <li>	Be able to receive email that contains hyperlinks to Websites in order for the Company to provide information to you.</li>
                                    <li>	Have access to an Internet service using one of the following Web browsers as may be applicable to your circumstances:</li>
                                </ol>
                                <div className="ml-5">
                                    <p><h4> Desktop Browsers</h4></p>
                                    <ul>
                                        <li> Internet Explorer V.11</li>
                                        <li> Chrome V.44</li>
                                        <li> Firefox V.40</li>
                                        <li> Safari V.8</li>
                                    </ul>
                                </div>
                                <div className="ml-5">

                                    <p> <h4> Mobile and Tablet Browsers/Operating Systems</h4></p>
                                    <ul>
                                        <li> Chrome V.45</li>
                                        <li> Firefox V.40</li>
                                        <li> Safari V.8</li>
                                        <li> iOS</li>
                                    </ul>
                                </div>

                                <p> If you are registering as a user and you do not have the required software and/or hardware, or if you do not wish to use electronic records and signatures for any other reason, DO NOT select "I Agree" where shown on screen and the registration process will be terminated.</p>

                                <p><span style={{ textDecoration: "underline" }}>Change in Hardware and Software Standards.</span> After you have given your consent, if the Company changes the hardware or software needed for you to access or retain required documents, and this change creates a material risk that you will not be able to access or retain required documents, the Company agrees to provide you with a revised statement of the hardware and software standards for access to and retention of the required documents.</p>

                                <p>Your rights relating to the Company’s products and services may be dependent on when you receive required documents from the Company.  The Company will notify you at the email address you provide below alerting you that the information is waiting for you at your secure mailbox at the web portal or as posted on our website, as designated in the email the Company would send to you at the email provided below.</p>

                                <p>At any time and for any reason you may opt out of receiving required notices or other legally required documents electronically.  To opt-out, you may contact the Company’s Customer Response Unit at 1-800-627-4200 or by updating your preferences on the Company website at <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">www.guardiananytime.com</a> /or by updating your information in the account profile section of the member web portal at <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">www.guardiananytime.com</a>.</p>

                                <p> Your consent does not mean that the Company must provide or accept documents electronically. Certain laws may require us to provide you with paper documents or the Company, at its option, may deliver paper documents to you if it chooses to do so. The Company may also require that certain communications from you be delivered to the Company on paper at a specified address.  Once per year you may request a free paper copy of any document sent to you electronically as part of this consent.  Nothing in this agreement is intended to restrict you from sending any communications to us by mail, email or otherwise.</p>

                                <p> The email address you provided to receive the electronic transmission of required documents is:</p>
                                {(_.get(this.props, "trustedFlag", "") === true && (this.state.prefillEmail === null || this.state.prefillEmail === '')) ?
                                    // <a href={`mailto:` + this.state.prefillEmail}>{this.state.prefillEmail}</a> :
                                    <label>Email:<input type="text" id="email" value={username} onBlur={(e) => this.emailValidation(e)} onChange={(e) => this.onHandleChange(e, "email")} style={{ marginLeft: "15px", outline: "0", borderWidth: "0 0 2px", borderColor: "black", fontFamily: "normal" }} /></label> :
                                    null}
                                {_.get(this.props, "trustedFlag", "") !== true ? <label aria-label="Please enter your e-mail id">Email:<input type="text" id="email" value={username} onBlur={(e) => this.emailValidation(e)} onChange={(e) => this.onHandleChange(e, "email")} style={{ marginLeft: "15px", outline: "0", borderWidth: "0 0 2px", borderColor: "black", fontFamily: "normal" }} /></label> : null}
                                {(_.get(this.props, "trustedFlag", "") === true && (this.state.prefillEmail !== null)) ?
                                    <a href={`mailto:` + this.state.prefillEmail}>{this.state.prefillEmail}</a> : null}
                                <div id="email-error" aria-hidden="false" className="form-group">
                                    {this.state.emailRequired
                                        ?
                                        <span className="error">
                                            <i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>
                                        {this.state.emailRequired}
                                        </span>
                                        :
                                        this.state.emailInvalid
                                            ?
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.emailInvalid}</span>
                                            :
                                            null}
                                {!emailValid &&
                                    <span id="emailValid-error" aria-label="Email is Invalid" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{'Email is Invalid'}</span>
                                }
                                </div>
                                <p> Unless you opt out, the Company will use the email address you have provided. Please carefully consider the email address that you provide, in case you share this email address with other individuals, as the Company may send you private or confidential information.</p>

                                <p> If you need to update the email address you provided for any electronic process or other contact information, you may contact the Company’s Customer Response Unit at <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">www.guardiananytime.com</a> /or by updating your information in the account profile section of the member web portal at <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">www.guardiananytime.com</a> .  The Company’s address and toll-free telephone number is listed below.</p>
                                {/* <div className="mt-4">
                                        <a href="mailto:appletonbilling@glic.com">appletonbilling@glic.com</a>
                                    </div> */}
                                <strong className="text-black mt-4 mb-4" style={{
                                    display: "block",
                                    fontSize: "1.125rem",
                                    fontWeight: 700,
                                    marginTop: "1.5rem !important",
                                    marginBottom: "1.5rem !important",
                                    lineHeight: "1.5rem",
                                    color: "#2B2B2B",
                                    fontFamily: "Effra Bold",
                                    boxSizing: "border-box",
                                    marginBlockStart: "1em",
                                    marginBlockEnd: "1em",
                                    marginInlineStart: "0px",
                                    marginInlineEnd: "0px",
                                    unicodeBidi: "isolate",
                                    textAlign: "left"
                                }}>
                                    The Guardian Life Insurance Company of America  <br></br>
                                    7 Hanover Square<br></br>New York, NY 10004<br></br>1-800-627-4200</strong>
                                <p>By signing below, I hereby consent to receive the electronic transmission of required documents as per the terms of the Electronic Consent Agreement.</p>
                                <div style={{ marginBottom: "40px" }}>

                                    <label style={{ display: "inline-block", marginRight: "6.5em", marginTop: "2px", paddingTop: "1.5em", width: "9em" }}>
                                        Signature:    <input type="text" value={this.state.name} onBlur={(e) => this.nameValidation(e)} onChange={(e) => this.onHandleChange(e, "name")} style={{ outline: "0", borderWidth: "0 0 2px", borderColor: "black", display: "inline-block", width: "11em", position: "relative", top: "-3.5em", fontFamily: "normal" }} /><br />
                                    </label>


                                    <label style={{ display: "inline-block", marginRight: ".5em", paddingTop: "1.5em", width: "6em" }}>
                                        Date:  <input type="text" format="MM/DD/YYYY" readOnly={true} value={this.state.date} style={{
                                            outline: "0", borderWidth: "0 0 2px", borderColor: "black", display: "inline-block", width: "8em", position: "relative", top: "-3.5em", fontFamily: "normal"
                                        }} /></label>
                                     <span
                                        id="name-error"
                                        aria-hidden="false"
                                        className="form-group">
                                        {this.state.nameRequired
                                            ?
                                            <span
                                                className="error"
                                                style={{paddingTop: '0rem'}}>
                                                <i
                                                    role="img"
                                                    aria-label="error"
                                                    aria-hidden="true"
                                                    className="fas fa-exclamation-circle">
                                                </i>
                                                {this.state.nameRequired}
                                            </span>
                                            :
                                            this.state.nameInvalid
                                                ?
                                                <span
                                                    className="error"
                                                    style={{paddingTop: '0rem'}}>
                                                    <i
                                                        role="img"
                                                        aria-hidden="true"
                                                        className="fas fa-exclamation-circle">
                                                    </i>
                                                    {this.state.nameInvalid}
                                                </span>
                                                :
                                                null}
                                    </span>
                                </div>
                            </div>
                            <span>GG-017711CA-GLIC-22</span>
                        </div>
                    </div>
                    <div className="intro pt-4">
                        <p>If you choose not to receive the electronic transmission of documents, you can submit a <a onClick={(e) => this.onOptout(e)} href="/#">PDF version of the Evidence of Insurability application.</a>
                        </p>
                    </div>
                    {this.state.displayDownnload === true ?
                        <div className="field-group  table-header-group mt-4 container justify-content-center">
                            <button className="btn btn-primary" onClick={(e) => this.handleConsent(e)}><i className="fa fa-download pr-2"></i>Your signed consent</button>
                            <button className="btn btn-primary float-right" onClick={(e) => this.handleContinue(e)}> Continue </button>
                        </div> : <button aria-label="Submit" className="btn btn-primary" style={{ margin: "auto", display: "block" }} onClick={(e) => this.handleSubmit(e)}>Submit</button>
                    }
                </div>


            </main>)
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(CAConstent);
