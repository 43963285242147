import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../../store/HomePage/HomePageAction";
import { months } from "../../../utils/constant";
import {
  moveToPreviousRefluxQuestion,
  scrollUp,
  imagePath,
} from "../../../utils/sharedFunction";
import Warning from "../../../components/Warning/Warning";
import PhysicianForm from "./PhysicianForm";
import SaveForLater from "../../../components/SaveForLater/SaveForLater";

let pageError = false;
let showCityDetails = "NO";

class ReflexQuestion extends Component {
  state = {
    yearsList: [],
    physicianAttendedFlag: null,
    dependencyFlag: null,
    monthQ: undefined,
    yearQ: undefined,
    beginMonthQ: undefined,
    beginYearQ: undefined,
    lastMonthQ: undefined,
    lastYearQ: undefined,
    medMonthQ: undefined,
    medYearQ: undefined,
    refluxData: {},
    physicians_choosen: [],
    physician_api_data: [],
    physician_data: {
      name: "",
      country: {
        description: "",
        code: "",
      },
      state: {
        code: "",
        description: "",
      },
      ui_state_cd: "",
      speciality: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      zip: "",
      phone: "",
      lastVisitMonth: "",
      lastVisitYear: "",
      lastVisitDateExplicitlyUnknown: false,
      physician_selected: false,
      errorValidation: {},
    },
    showForm: false,
    addOrEditStatus: "",
    editIndex: "",
    phyIdFromApi: "",
    isDeleteChecked: false,
    lastVisitCheckedArray: [],
    dataFromPhysicianAPI: [],
    lastVisitMonthError: false,
    lastVisitYearError: false,
    firstTimeAdd: false,
    isSaved: false,
    isOpened: false,
    // displayPhysician: false,
  };

  setInitialData = () => {
    let currentYear = new Date().getFullYear();
    //let previousYear = currentYear-(100)
    let years = [];
    for (
      let previousYear = currentYear - 100;
      previousYear <= currentYear;
      previousYear++
    ) {
      years.push(previousYear);
      years.sort((a, b) => b - a);
    }
    this.setState({ yearsList: years });
    let monthYearAnswer;
    let monthYearBeginAnswer;
    let monthYearLastAnswer;
    let monthYearMedAnswer;
    let dependencyFlag;
    //let physicians = this.state.physicians_choosen;
    let refluxData =
      _.get(this.props, "location.state.reflux") &&
      JSON.parse(JSON.stringify(_.get(this.props, "location.state.reflux")));
    refluxData &&
      refluxData.disclosureData &&
      refluxData.disclosureData.disclosure_question_info &&
      refluxData.disclosureData.disclosure_question_info.map((question) => {
        //if(question.disclosure_qstn_desc.includes(refluxData.disclosureData.disclosure_desc)){
        if (
          refluxData.disclosureData.disclosure_desc.includes(
            question.disclosure_qstn_desc
          )
        ) {
          question.disclosure_qstn_ans =
            refluxData.disclosureData.answer_disclosure_info_value &&
            refluxData.disclosureData.answer_disclosure_info_value;
        }
        //console.log(refluxData.disclosureData.disclosure_question_info)
        if (question.disclosure_qstn_desc.includes("month")) {
          if (question.disclosure_qstn_ans) {
            monthYearAnswer = question.disclosure_qstn_ans;
            //console.log(question.disclosure_qstn_ans,question.disclosure_qstn_ans.split('/')[0],question.disclosure_qstn_ans.split('/')[1])
            // this.setState({
            //     monthQ:question.disclosure_qstn_ans.split('/')[0],
            //     yearQ: question.disclosure_qstn_ans.split('/')[1]
            // })
          }
        }
        if (
          question.disclosure_qstn_desc.includes("begin using this substance?")
        ) {
          if (question.disclosure_qstn_ans) {
            monthYearBeginAnswer = question.disclosure_qstn_ans;
          }
        }
        if (
          question.disclosure_qstn_desc.includes("last use this substance?")
        ) {
          if (question.disclosure_qstn_ans) {
            monthYearLastAnswer = question.disclosure_qstn_ans;
          }
        }
        if (
          question.disclosure_qstn_desc.includes(
            "start taking these medications?"
          )
        ) {
          if (question.disclosure_qstn_ans) {
            monthYearMedAnswer = question.disclosure_qstn_ans;
          }
        }
        if (
          question.disclosure_qstn_desc.includes("dependency issues or relapse")
        ) {
          if (question.disclosure_qstn_ans) {
            dependencyFlag = this.changeToBoolean(question.disclosure_qstn_ans);
          }
        }
        // else{
        //     this.setState({
        //         monthQ:undefined,
        //         yearQ: undefined
        //     })
        // }
        return question;
      });
    if (monthYearAnswer !== undefined) {
      this.setState({
        monthQ: monthYearAnswer.split("/")[0],
        yearQ: monthYearAnswer.split("/")[1],
      });
    }
    if (monthYearBeginAnswer !== undefined) {
      this.setState({
        beginMonthQ: monthYearBeginAnswer.split("/")[0],
        beginYearQ: monthYearBeginAnswer.split("/")[1],
      });
    }
    if (monthYearLastAnswer !== undefined) {
      // console.log(monthYearLastAnswer,"monthLastAnswer")
      this.setState({
        lastMonthQ: monthYearLastAnswer.split("/")[0],
        lastYearQ: monthYearLastAnswer.split("/")[1],
      });
    }
    if (monthYearMedAnswer !== undefined) {
      this.setState({
        medMonthQ: monthYearMedAnswer.split("/")[0],
        medYearQ: monthYearMedAnswer.split("/")[1],
      });
    }
    if (dependencyFlag !== undefined) {
      this.setState({ dependencyFlag });
    }
    // else {
    //     this.setState({
    //         monthQ: undefined,
    //         yearQ: undefined,
    //         beginMonthQ:undefined,
    //         beginYearQ:undefined,
    //         lastMonthQ:undefined,
    //         lastYearQ:undefined

    //     })
    // }

    if (
      refluxData.disclosureData &&
      refluxData.disclosureData.phycisian_info &&
      refluxData.disclosureData.phycisian_info.physicians_choosen.length > 0
    ) {
      let a =
        refluxData &&
        refluxData.disclosureData &&
        refluxData.disclosureData.phycisian_info &&
        refluxData.disclosureData.phycisian_info;

      if (a) {
        // console.log(a.physician_attended_flag,"if flag")
        this.setState({
          physicianAttendedFlag: a.physician_attended_flag,
          physicians_choosen: a.physicians_choosen && a.physicians_choosen,
        });
        let wholeData = [];
        a &&
          a.physicians_choosen &&
          a.physicians_choosen.map((data, index) => {
            //console.log(data)
            if (data.physician_selected === true) {
              wholeData = [...wholeData, index];
            }
            return data;
          });
        this.setState({
          //lastVisitCheckedArray:this.unique([...this.state.lastVisitCheckedArray,index])
          lastVisitCheckedArray: wholeData,
        });
      }
    } else if (
      refluxData.disclosureData &&
      refluxData.disclosureData.phycisian_info
    ) {
      let a =
        refluxData &&
        refluxData.disclosureData &&
        refluxData.disclosureData.phycisian_info &&
        refluxData.disclosureData.phycisian_info;
      if (a) {
        // console.log(a.physician_attended_flag,"else flag")
        this.setState({
          physicianAttendedFlag: a.physician_attended_flag,
        });
      }
    }

    this.setState({
      refluxData:
        _.get(this.props, "location.state.reflux") &&
        _.get(this.props, "location.state.reflux"),
    });
    // let checkCondition = refluxData.condition_name.includes("Medication other than as prescribed") && (refluxData.disclosureData.disclosure_desc.includes("Cannabis") || refluxData.disclosureData.disclosure_desc.includes("Central nervous system depressants") || refluxData.disclosureData.disclosure_desc.includes("Central nervous system stimulants") || refluxData.disclosureData.disclosure_desc.includes("Opiates") || refluxData.disclosureData.disclosure_desc.includes("Other drugs"))
    // this.setState({ displayPhysician: checkCondition })
    // console.log(checkCondition,"condition")
    //console.log(_.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[]),dataFromPhysicianAPI)
    // let dataFromPhysicianAPI = _.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[])
    // this.setState({
    //     dataFromPhysicianAPI : _.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[])
    // })
    //console.log(dataFromPhysicianAPI)
    // this.setState({
    //     refluxData: refluxData
    // })

    scrollUp();
  };
  unique = (array) => {
    let arr = [];
    for (let i = 0; i < array.length; i++) {
      if (!arr.some((x) => x.id === array[i].id)) {
        arr.push(array[i]);
      }
    }
    return arr;
  };

  componentDidMount() {
    this.setInitialData();
    this.props.saveEoiData(this.props.prefillData);
    // console.log(this.props, "props");
    // console.log(_.get(this.props, "activeCondition"), _.get(this.props, "countCondition"), "checkdata")
    window.addEventListener("popstate", () => {
      this.props.history.goForward();
      this.props.bClick(true);
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      //console.log("kkkkkkkkkkkkkkkkkkkkkk")
      this.setState({
        yearsList: [],
        physicianAttendedFlag: null,
        dependencyFlag: null,
        monthQ: undefined,
        yearQ: undefined,
        beginMonthQ: undefined,
        beginYearQ: undefined,
        lastMonthQ: undefined,
        lastYearQ: undefined,
        medMonthQ: undefined,
        medYearQ: undefined,
        refluxData: {},
        physicians_choosen: [],
        physician_data: {
          name: "",
          country: {
            description: "",
            code: "",
          },
          state: {
            code: "",
            description: "",
          },
          ui_state_cd: "",
          speciality: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          zip: "",
          phone: "",
          lastVisitMonth: "",
          lastVisitYear: "",
          lastVisitDateExplicitlyUnknown: false,
          physician_selected: false,
        },
        showForm: false,
        addOrEditStatus: "",
        editIndex: "",
        phyIdFromApi: "",
        isDeleteChecked: false,
        lastVisitCheckedArray: [],
      });
      this.setInitialData();
      //console.log(_.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[]),"uuuuuu")
    }
  }

  handleBrowser = () => {
    this.props.bClick(false);
  };

  changeZip = (event) => {
    var physician_data = { ...this.state.physician_data };
    let errorValidation = { ...this.state.errorValidation };
    physician_data["zip"] = event.target.value;

    if (event.target.value.length > 0) {
      errorValidation["zipError"] = "";
      this.setState({ errorValidation });
    }

    if (event.target.value.trim().length === 1) {
      if (isNaN(event.target.value)) {
        this.setState({
          zipMaxLength: 7,
          zipMinLength: 6,
          zipCodeCountry: "CAN",
        });

        physician_data["zip"] = event.target.value;
        this.setState({ physician_data });
      } else {
        this.setState({
          zipMaxLength: 10,
          zipMinLength: 5,
          zipCodeCountry: "US",
        });
        physician_data["zip"] = event.target.value;
        this.setState({ physician_data });
      }
    } else {
      if (this.state.zipCodeCountry === "US") {
        let regex = /^[0-9 -]+$/i;
        if (
          event.target.value === "" ||
          event.target.value.trim().length !== 0 ||
          regex.test(event.target.value)
        ) {
          physician_data["zip"] = event.target.value.trim();
          this.setState({ physician_data, zipCodeCountry: "US" });
        }
      }
      if (this.state.zipCodeCountry === "CAN") {
        let regex = /^[A-Za-z0-9 -]+$/i;
        if (event.target.value === "" || regex.test(event.target.value)) {
          physician_data["zip"] = event.target.value;
          this.setState({ physician_data, zipCodeCountry: "CAN" });
        }
      }
    }
  };

  handleDataChange = (e, type) => {
    let physician_data = { ...this.state.physician_data };

    let errorValidation = { ...this.state.errorValidation };
    physician_data[type] = e.target.value;
    if (e.target.value.length > 0 && type === "name") {
      errorValidation["nameError"] = "";
      this.setState({ errorValidation });
    }
    if (e.target.value.length > 0 && type === "speciality") {
      errorValidation["specialityError"] = "";
      this.setState({ errorValidation });
    }

    if (e.target.value.length > 0 && type === "addressLine1") {
      errorValidation["addressLine1Error"] = "";
      this.setState({ errorValidation });
    }
    if (e.target.value.length > 0 && type === "phone") {
      errorValidation["phoneError"] = "";
      this.setState({ errorValidation });
    }
    if (e.target.value.length === 12 && type === "phone") {
      errorValidation["phoneLengthError"] = "";
      this.setState({ errorValidation });
    }
    this.setState({
      physician_data,
    });
  };

  blurZip = (event) => {
    let errorValidation = { ...this.state.physician_data.errorValidation };
    errorValidation["zipError"] = "";
    if (event.target.value && event.target.value.trim().length > 0) {
      errorValidation["zipFormat"] = "";
      this.setState({ errorValidation });
    }

    if (this.state.zipCodeCountry === "US") {
      var reg = /^\d{5}([-])?(\d{4})?$/gm;
      if (
        event.target.value.trim().length < 5 ||
        !reg.test(event.target.value.trim())
      ) {
        _.set(this.props, "physicianStateCity.state_province_code", "");
        _.set(this.props, "physicianStateCity.city.city_name", []);
        _.set(this.props, "physicianStateCity.cityFlag", "N");
        //this.setState({"zipFormat" : "Zip format is not correct.Try again"});
        errorValidation["zipFormat"] = "Zip format is not correct.Try again";
        this.setState({ errorValidation });
        return false;
      } else if (
        event.target.value.trim().length >= 5 &&
        event.target.value.trim().length <= 10
      ) {
        this.props.getStateandCity(event.target.value, "physician");
      }
    } else if (this.state.zipCodeCountry === "CAN") {
      reg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      if (
        event.target.value.trim().length < 6 ||
        !reg.test(event.target.value.trim())
      ) {
        _.set(this.props, "physicianStateCity.state_province_code", "");
        _.set(this.props, "physicianStateCity.city.city_name", []);
        _.set(this.props, "physicianStateCity.cityFlag", "N");
        this.setState({ zipFormat: "Zip format is not correct.Try again" });
        return false;
      } else if (
        event.target.value.trim().length === 6 ||
        event.target.value.trim().length === 7
      ) {
        this.props.getStateandCity(event.target.value, "physician");
      }
    }
    showCityDetails = "YES";

    // this.props.getStateandCity(event.target.value,"physician");
  };

  handleDeleteCheckBox = (e) => {
    let deleteChecked = this.state.isDeleteChecked;
    this.setState({ isDeleteChecked: !deleteChecked });
  };

  addOrEditSave = (e) => {
    e.preventDefault();
    const {
      addOrEditStatus,
      physicians_choosen,
      physician_data,
      isDeleteChecked,
    } = this.state;
    let errorValidation = { ...this.state.physician_data.errorValidation };

    let physicianInfoError = false;
    let name = _.get(this.state, "physician_data.name", "");
    let speciality = _.get(this.state, "physician_data.speciality", "");
    let addressLine1 = _.get(this.state, "physician_data.addressLine1", "");
    let phone = _.get(this.state, "physician_data.phone", "");
    let zipCode = _.get(this.state, "physician_data.zip", "");
    // let city = _.get(this.state,"physician_data.city","");

    if (name.trim().length <= 0) {
      errorValidation["nameError"] = "Name is required";
      this.setState({ errorValidation });
      physicianInfoError = true;
    }

    if (zipCode.trim().length <= 0) {
      errorValidation["zipError"] = "Zip is required";
      this.setState({ errorValidation });
      physicianInfoError = true;
    } else if (this.state.zipCodeCountry === "US") {
      var reg = /^\d{5}([-])?(\d{4})?$/gm;
      if (zipCode.trim().length < 5 || !reg.test(zipCode.trim())) {
        errorValidation["zipFormat"] = "Zip format is not correct. Try again";
        physicianInfoError = true;
      }
    } else if (this.state.zipCodeCountry === "CAN") {
      reg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      if (zipCode.trim().length < 6 || !reg.test(zipCode.trim())) {
        errorValidation["zipFormat"] = "Zip format is not correct. Try again";
        physicianInfoError = true;
      }
    }
    // console.log(city,"city",city.trim().length === 0 ,city.trim().length === undefined)
    // if (city.trim().length === 0 || city.trim().length === undefined) {
    //     errorValidation["cityError"] = "City is required";
    //     this.setState({ errorValidation });
    //     physicianInfoError = true;
    // }

    if (speciality.trim().length <= 0) {
      errorValidation["specialityError"] = "Speciality is required";
      this.setState({ errorValidation });
      physicianInfoError = true;
    }

    if (addressLine1.trim().length <= 0) {
      errorValidation["addressLine1Error"] = "Address is required";
      this.setState({ errorValidation });
      physicianInfoError = true;
    }
    if (phone.trim().length <= 0) {
      errorValidation["phoneError"] = "phone is required";
      this.setState({ errorValidation });
      physicianInfoError = true;
    } else if (phone.trim().length < 12) {
      errorValidation["phoneLengthError"] = "phone must be 10 digits";
      this.setState({ errorValidation });
      physicianInfoError = true;
    }
    //console.log(!isDeleteChecked && addOrEditStatus === "edit",!isDeleteChecked , addOrEditStatus,"llllllllllllllll" )
    if (!physicianInfoError) {
      if (addOrEditStatus === "add") {
        physician_data.city = document.getElementById("eoi-city").value;
        physician_data.ui_state_cd = _.get(
          this.props,
          "physicianStateCity.state_province_code",
          ""
        );
        //let physicians_choosen = JSON.parse(JSON.stringify(physicians_choosen))
        //console.log(physicians_choosen,physicians_choosen.length)
        // if(physicians_choosen && physicians_choosen.length === 0){
        //     physician_data.physician_selected = true
        // }
        //console.log(physician_data)
        this.setState({
          // physicians_choosen: [...JSON.parse(JSON.stringify(physicians_choosen)), physician_data],
          showForm: false,
          addOrEditStatus: "",
        });
        this.setState({ addPhysician: "" });

        let tempObj = {};
        Object.assign(tempObj, {
          name: this.state.physician_data.name,
          addressLine1: this.state.physician_data.addressLine1,
          addressLine2: this.state.physician_data.addressLine2,
          city: this.state.physician_data.city,
          zip: this.state.physician_data.zip,
          phone: this.state.physician_data.phone,
          ui_state_cd: this.state.physician_data.ui_state_cd,
          speciality: this.state.physician_data.speciality,
          physician_status: "ADD",
        });
        if (
          _.get(
            this.props,
            "prefillData.eoi_data.health_question_info.physicians",
            []
          ).length === 0
        ) {
          this.setState({
            firstTimeAdd: true,
          });
        } else {
          this.setState({
            firstTimeAdd: false,
          });
        }
        //console.log(tempObj,"tempObj")
        let temp_physician_api = [
          ...JSON.parse(
            JSON.stringify(
              _.get(
                this.props,
                "prefillData.eoi_data.health_question_info.physicians",
                []
              )
            )
          ),
          tempObj,
        ];
        let finalAdd = JSON.parse(JSON.stringify(temp_physician_api));
        _.set(
          this.props,
          "prefillData.eoi_data.health_question_info.physicians",
          finalAdd
        );

        // this.setState({
        //     physician_api_data : [...this.state.physician_api_data, tempObj]
        // })
        this.props.addPhysicianData(_.get(this.props, "prefillData", {}));
      } else if (!isDeleteChecked && addOrEditStatus === "edit") {
        //console.log(physician_data)
        let tempPhycisians = JSON.parse(JSON.stringify(physicians_choosen));
        physician_data.city = document.getElementById("eoi-city").value;
        physician_data.ui_state_cd = _.get(
          this.props,
          "physicianStateCity.state_province_code",
          ""
        );
        tempPhycisians.splice(this.state.editIndex, 1, physician_data);
        //console.log(tempPhycisians,"tttttttttttt")
        this.setState({
          physicians_choosen: tempPhycisians,
          addOrEditStatus: "",
          editIndex: "",
          phyIdFromApi: "",
          showForm: false,
        });

        let temp_physician_api = JSON.parse(
          JSON.stringify(
            _.get(
              this.props,
              "prefillData.eoi_data.health_question_info.physicians",
              []
            )
          )
        );
        //let finalAdd = JSON.parse(JSON.stringify(temp_physician_api))

        temp_physician_api.map((eachData, index) => {
          //console.log(eachData)
          if (eachData.physicianId === this.state.phyIdFromApi) {
            Object.assign(eachData, {
              name: this.state.physician_data.name,
              addressLine1: this.state.physician_data.addressLine1,
              addressLine2: this.state.physician_data.addressLine2,
              city: this.state.physician_data.city,
              zip: this.state.physician_data.zip,
              phone: this.state.physician_data.phone,
              ui_state_cd: this.state.physician_data.ui_state_cd,
              speciality: this.state.physician_data.speciality,
              physicianId: this.state.physician_data.physicianId,
              // "physician_selected":this.state.physician_data.physician_selected,
              physician_status: "EDIT",
            });
          }
          //console.log(eachData)
          return eachData;
        });

        _.set(
          this.props,
          "prefillData.eoi_data.health_question_info.physicians",
          temp_physician_api
        );
        //console.log(temp_physician_api)
        this.props.addPhysicianData(_.get(this.props, "prefillData", {}));
        // this.setState({
        //     offLocList:this.state.offLocList
        // })

        // const temp_physician_api = JSON.parse(JSON.stringify(_.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[]))),tempObj
        // const index = this.state.editIndex;
        // //console.log(index,temp_physician_api[index],temp_physician_api,tempPhycisians)
        // temp_physician_api[index] = {...tempPhycisians};
        // temp_physician_api[index].name=tempPhycisians.name;
        // temp_physician_api[index].addressLine1=tempPhycisians.addressLine1;
        // temp_physician_api[index].addressLine2=tempPhycisians.addressLine2;
        // temp_physician_api[index].city=tempPhycisians.city;
        // temp_physician_api[index].ui_state_cd=tempPhycisians.ui_state_cd;
        // temp_physician_api[index].zip=tempPhycisians.zip;
        // temp_physician_api[index].speciality=tempPhycisians.speciality;
        // temp_physician_api[index].phone=tempPhycisians.phone;
        // temp_physician_api[index].physician_status="EDIT";

        // //console.log(temp_physician_api)
        // _.set(this.props,"prefillData.eoi_data.health_question_info.physicians",temp_physician_api)

        // //console.log(this.props.prefillData)
        // this.props.addPhysicianData(_.get(this.props,"prefillData",{}));
      } else if (isDeleteChecked) {
        let tempPhycisians = JSON.parse(JSON.stringify(physicians_choosen));
        tempPhycisians.splice(this.state.editIndex, 1);
        this.setState({
          physicians_choosen: tempPhycisians,
          addOrEditStatus: "",
          editIndex: "",
          showForm: false,
          isDeleteChecked: false,
        });
      }

      this.setState({
        physician_data: {
          name: "",
          country: {
            description: "",
            code: "",
          },
          state: {
            code: "",
            description: "",
          },
          ui_state_cd: "",
          speciality: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          zip: "",
          phone: "",
          lastVisitMonth: "",
          lastVisitYear: "",
          lastVisitDateExplicitlyUnknown: false,
          physician_selected: false,
        },
        addOrEditStatus: "",
        showForm: false,
      });
      this.props.clearPhysisicanStateCityDetails();
    }
  };

  handleCancelPopup = () => {
    _.set(this.props, "physicianStateCity.state_province_code", "");
    _.set(this.props, "physicianStateCity.city.city_name", []);
    _.set(this.props, "physicianStateCity.cityFlag", "");
    this.setState({ showForm: false });
  };

  onAddPhysician = () => {
    this.setState({
      physician_data: {
        name: "",
        country: {
          description: "",
          code: "",
        },
        state: {
          code: "",
          description: "",
        },
        ui_state_cd: "",
        speciality: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        phone: "",
        lastVisitMonth: "",
        lastVisitYear: "",
        lastVisitDateExplicitlyUnknown: false,
        physician_selected: false,
      },
      addOrEditStatus: "add",
      showForm: true,
      errorValidation: {},
    });
  };

  onEditPhysician = (data, index, physicianId) => {
    this.props.getStateandCity(data.zip, "physician");
    this.setState({
      physician_data: data,
      editIndex: index,
      phyIdFromApi: physicianId,
      addOrEditStatus: "edit",
      showForm: true,
    });
  };
  getPhyData = (id, phycisianData) => {
    let tempPhycisiansA = JSON.parse(
      JSON.stringify(this.state.physicians_choosen)
    );
    let a;
    tempPhycisiansA.map((data) => {
      if (data.physicianId === id) {
        a = data;
        //console.log(data)
        //return data
      }
      return data;
    });
    if (a === undefined) {
      return phycisianData;
    } else {
      return a;
    }
    //return phycisianData
    //let tempPhycisiansAPI = JSON.parse(JSON.stringify(_.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[])))
  };
  handleMonthQ = (e) => {
    let refluxData = JSON.parse(JSON.stringify(this.state.refluxData));
    // console.log(e,refluxData,"handle month")
    refluxData.disclosureData.disclosure_question_info.map((question) => {
      // && question.disclosure_qstn_id === e.target.id
      // if(refluxData.condition_name.includes("Medication other than as prescribed")){
      if (
        question.disclosure_qstn_desc.includes("begin using this substance?") &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          beginMonthQ: e.target.value,
          monthBeginQError: "",
        });
        question.disclosure_qstn_ans =
          e.target.value + "/" + this.state.beginYearQ;
      }
      if (
        question.disclosure_qstn_desc.includes("last use this substance?") &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          lastMonthQ: e.target.value,
          monthQLastError: "",
        });
        question.disclosure_qstn_ans =
          e.target.value + "/" + this.state.lastYearQ;
      }
      if (
        question.disclosure_qstn_desc.includes(
          "start taking these medications?"
        ) &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          medMonthQ: e.target.value,
          monthQMedError: "",
        });
        question.disclosure_qstn_ans =
          e.target.value + "/" + this.state.medYearQ;
      }

      // }
      if (
        question.disclosure_qstn_desc.includes("month") &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          monthQ: e.target.value,
          monthQError: "",
        });

        question.disclosure_qstn_ans = e.target.value + "/" + this.state.yearQ;
      }
      // else if (question.disclosure_qstn_id === e.target.id) {
      //     this.setState({
      //         monthQ: e.target.value,
      //         monthQError: ''
      //     })

      //     question.disclosure_qstn_ans = e.target.value + '/' + this.state.yearQ

      // }
      return question;
    });
    this.setState({
      refluxData,
    });
  };
  handleYearQ = (e) => {
    let refluxData = JSON.parse(JSON.stringify(this.state.refluxData));
    // console.log(refluxData,e,e.target.value,"handle year")
    refluxData.disclosureData.disclosure_question_info.map((question) => {
      // if(refluxData.condition_name.includes("Medication other than as prescribed")){
      if (
        question.disclosure_qstn_desc.includes("begin using this substance?") &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          beginYearQ: e.target.value,
          yearQBeginError: "",
        });
        question.disclosure_qstn_ans =
          this.state.beginMonthQ + "/" + e.target.value;
      }
      if (
        question.disclosure_qstn_desc.includes("last use this substance?") &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          lastYearQ: e.target.value,
          yearQLastError: "",
        });
        question.disclosure_qstn_ans =
          this.state.lastMonthQ + "/" + e.target.value;
      }
      if (
        question.disclosure_qstn_desc.includes(
          "start taking these medications?"
        ) &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          medYearQ: e.target.value,
          yearQMedError: "",
        });
        question.disclosure_qstn_ans =
          this.state.medMonthQ + "/" + e.target.value;
      }

      // }
      if (
        question.disclosure_qstn_desc.includes("month/year") &&
        question.disclosure_qstn_id === e.target.id
      ) {
        this.setState({
          yearQ: e.target.value,
          yearQError: "",
        });
        question.disclosure_qstn_ans = this.state.monthQ + "/" + e.target.value;
      }
      // else if (question.disclosure_qstn_id === e.target.id) {
      //     this.setState({
      //         yearQ: e.target.value,
      //         yearQError: ''
      //     })
      //     question.disclosure_qstn_ans = this.state.monthQ + '/' + e.target.value

      // }
      return question;
    });
    this.setState({
      refluxData,
    });
  };
  handleQuestion = (e) => {
    let refluxData = JSON.parse(JSON.stringify(this.state.refluxData));
    refluxData.disclosureData.disclosure_question_info.map((question) => {
      const isBoolean =
        e.target.id === "btnTrue" || e.target.id === "btnFalse" ? "5" : null;
      if (
        question.disclosure_qstn_id === e.target.id ||
        question.disclosure_qstn_id === isBoolean
      ) {
        if (
          question.disclosure_qstn_type === "BOOLEAN" ||
          e.target.type === "radio"
        ) {
          if (
            question.disclosure_qstn_desc.includes(
              "dependency issues or relapse"
            )
          ) {
            question.disclosure_qstn_ans = this.changeToBoolean(e.target.value);
            this.setState({
              dependencyFlagError: "",
              dependencyFlag: this.changeToBoolean(e.target.value),
            });
          }
        }
        if (e.target.type === "text") {
          question.disclosure_qstn_ans = e.target.value;
          if (
            question.disclosure_qstn_desc.includes("duration") &&
            question.disclosure_qstn_ans.trim().length > 0
          ) {
            this.setState({ disAnsDuration: "" });
          }
          if (
            question.disclosure_qstn_desc.includes("treatment") &&
            question.disclosure_qstn_ans.trim().length > 0
          ) {
            this.setState({ disAnsTreatment: "" });
          }
          if (
            question.disclosure_qstn_desc.includes("recovery") &&
            question.disclosure_qstn_ans.trim().length > 0
          ) {
            this.setState({ disAnsRecovery: "" });
          }
          if (
            question.disclosure_qstn_desc.includes(
              "often do you use this substance?"
            ) &&
            question.disclosure_qstn_ans.trim().length > 0
          ) {
            this.setState({ disAnsSubs: "" });
          }
          if (
            question.disclosure_qstn_desc.includes("central nervous system") &&
            question.disclosure_qstn_ans.trim().length > 0
          ) {
            this.setState({ disAnsCon: "" });
          }
          if (
            question.disclosure_qstn_desc.includes("medications prescribed") &&
            question.disclosure_qstn_ans.trim().length > 0
          ) {
            this.setState({ disAnsPre: "" });
          }
        } else {
          //let month, year;
          if (
            question.disclosure_qstn_desc.includes(
              "begin using this substance?"
            )
          ) {
            if (e.target.name === "month") {
              //month = e.target.value
              this.setState({
                beginMonthQ: e.target.value,
              });
            }
            if (e.target.name === "year") {
              //year = e.target.value
              this.setState({
                beginYearQ: e.target.value,
              });
            }
            question.disclosure_qstn_ans =
              this.state.beginMonthQ + "/" + this.state.beginYearQ;
          }
          if (
            question.disclosure_qstn_desc.includes("last use this substance?")
          ) {
            if (e.target.name === "month") {
              //month = e.target.value
              this.setState({
                lastMonthQ: e.target.value,
              });
            }
            if (e.target.name === "year") {
              //year = e.target.value
              this.setState({
                lastYearQ: e.target.value,
              });
            }
            question.disclosure_qstn_ans =
              this.state.lastMonthQ + "/" + this.state.lastYearQ;
          }
          if (question.disclosure_qstn_desc.includes("month")) {
            if (e.target.name === "month") {
              //month = e.target.value
              this.setState({
                monthQ: e.target.value,
              });
            }
            if (e.target.name === "year") {
              //year = e.target.value
              this.setState({
                yearQ: e.target.value,
              });
            }
            question.disclosure_qstn_ans =
              this.state.monthQ + "/" + this.state.yearQ;
          }
          if (
            question.disclosure_qstn_desc.includes(
              "start taking these medications?"
            )
          ) {
            if (e.target.name === "month") {
              this.setState({
                medMonthQ: e.target.value,
              });
            }
            if (e.target.name === "year") {
              this.setState({
                medYearQ: e.target.value,
              });
            }
            question.disclosure_qstn_ans =
              this.state.medMonthQ + "/" + this.state.medYearQ;
          }
        }
      }
      return question;
    });
    this.setState({
      refluxData,
    });
  };

  handleNext = () => {
    //need to do validations
    this.setState({
      monthQError: "",
      yearQError: "",
      monthQ: "",
      yearQ: "",
      beginMonthQ: "",
      beginYearQ: "",
      lastMonthQ: "",
      lastYearQ: "",
      medMonthQ: "",
      medYearQ: "",
      disAnsDuration: "",
      disAnsTreatment: "",
      disAnsSubs: "",
      disAnsCon: "",
      disAnsRecovery: "",
      disAnsPre: "",
      selectMorePhysicians: "",
      monthBeginQError: "",
      monthQLastError: "",
      yearQBeginError: "",
      yearQLastError: "",
      yearQMedError: "",
      monthQMedError: "",
      dependencyFlag: "",
      dependencyFlagError: "",
    });
    pageError = false;
    let disQInfo =
      this.state.refluxData.disclosureData.disclosure_question_info;
    let dataFromPhysicianAPI = _.get(
      this.props,
      "prefillData.eoi_data.health_question_info.physicians",
      []
    );

    if (this.state.physicianAttendedFlag && dataFromPhysicianAPI.length <= 0) {
      //add a physician
      this.setState({ addPhysician: "Add a physician" });
      pageError = true;
    }
    if (this.state.physicianAttendedFlag === null) {
      // if(this.state.refluxData.condition_name !== "Medication other than as prescribed"){
      this.setState({
        physicianAttendedError: "Specify if you attended a physician",
      });
      pageError = true;
      // }
    }
    // if(this.state.dependencyFlag === null || this.state.dependencyFlag === ""){
    //     this.setState({"dependencyFlagError" : "Specify Condition"});
    // }

    disQInfo &&
      disQInfo.map((itr) => {
        if (itr.disclosure_qstn_desc.includes("month")) {
          //   itr.disclosure_qstn_desc.includes("begin using this substance?") || itr.disclosure_qstn_desc.includes("last use this substance?")) {
          let disAnsSplit = itr.disclosure_qstn_ans.split("/");
          if (
            itr.disclosure_qstn_ans.trim().length === 0 ||
            disAnsSplit === undefined
          ) {
            this.setState({ yearQError: "Year is required" });
            this.setState({ monthQError: "Month is required" });
            pageError = true;
          } else {
            if (
              disAnsSplit[0] === "" ||
              disAnsSplit[0].includes(undefined) ||
              disAnsSplit[0]?.trim().length <= 0
            ) {
              this.setState({
                monthQError: "Month is required",
                yearQError: "Year is required",
              });
              pageError = true;
            }
            if (
              disAnsSplit[1] === "" ||
              disAnsSplit[1].includes(undefined) ||
              (disAnsSplit[1] && disAnsSplit[1]?.trim().length <= 0)
            ) {
              // if (disAnsSplit[1] === undefined || disAnsSplit[1] === '' || (disAnsSplit === undefined) || (disAnsSplit[1] && disAnsSplit[1]?.trim().length <= 0) ) {
              this.setState({
                yearQError: "Year is required",
                monthQError: "Month is required",
              });
              pageError = true;
            }
          }
        }
        if (itr.disclosure_qstn_desc.includes("begin using this substance?")) {
          let disAnsSplit = itr.disclosure_qstn_ans.split("/");
          if (
            itr.disclosure_qstn_ans.trim().length === 0 ||
            disAnsSplit === undefined
          ) {
            this.setState({ yearQBeginError: "Year is required" });
            this.setState({ monthBeginQError: "Month is required" });
            pageError = true;
          } else {
            if (
              disAnsSplit[0]?.trim().length <= 0 ||
              disAnsSplit[0] === "" ||
              disAnsSplit[0] === undefined
            ) {
              this.setState({
                monthBeginQError: "Month is required",
                yearQBeginError: "Year is required",
                monthQLastError: "Month is required",
                yearQLastError: "Year is required",
              });
              pageError = true;
            }
            if (
              disAnsSplit[1] === "" ||
              disAnsSplit === undefined ||
              disAnsSplit[1] === undefined ||
              (disAnsSplit[1] && disAnsSplit[1]?.trim().length <= 0)
            ) {
              this.setState({
                yearQBeginError: "Year is required",
                monthBeginQError: "Month is required",
                monthQLastError: "Month is required",
                yearQLastError: "Year is required",
              });
              pageError = true;
            }
          }
        }
        if (
          itr.disclosure_qstn_desc.includes("start taking these medications?")
        ) {
          let disAnsSplit = itr.disclosure_qstn_ans.split("/");
          console.log(disAnsSplit, "split");
          if (
            itr.disclosure_qstn_ans.trim().length === 0 ||
            disAnsSplit === undefined
          ) {
            this.setState({ yearQMedError: "Year is required" });
            this.setState({ monthQMedError: "Month is required" });
            pageError = true;
          } else {
            if (
              disAnsSplit[0] === "" ||
              disAnsSplit[0] === undefined ||
              disAnsSplit[0]?.trim().length <= 0
            ) {
              this.setState({
                monthQMedError: "Month is required",
                yearQMedError: "Year is required",
              });
              pageError = true;
            }
            if (
              disAnsSplit[1] === undefined ||
              disAnsSplit[1] === "" ||
              (disAnsSplit[1] && disAnsSplit[1].trim().length <= 0) ||
              disAnsSplit[1] === undefined
            ) {
              this.setState({
                yearQMedError: "Year is required",
                monthQMedError: "Month is required",
              });
              pageError = true;
            }
          }
        }
        if (itr.disclosure_qstn_desc.includes("last use this substance?")) {
          //   itr.disclosure_qstn_desc.includes("begin using this substance?") || itr.disclosure_qstn_desc.includes("last use this substance?")) {
          let disAnsSplit = itr.disclosure_qstn_ans.split("/");
          if (
            itr.disclosure_qstn_ans.trim().length === 0 ||
            disAnsSplit === undefined
          ) {
            this.setState({ yearQLastError: "Year is required" });
            this.setState({ monthQLastError: "Month is required" });
            pageError = true;
          } else {
            if (
              disAnsSplit[0] === "" ||
              disAnsSplit[0].trim().length <= 0 ||
              disAnsSplit[0].includes(undefined)
            ) {
              this.setState({
                monthQLastError: "Month is required",
                yearQLastError: "Year is required",
                yearQBeginError: "Year is required",
                monthBeginQError: "Month is required",
              });
              pageError = true;
            }
            if (
              disAnsSplit === undefined ||
              disAnsSplit[1] === "" ||
              (disAnsSplit[1] && disAnsSplit[1].trim().length <= 0) ||
              disAnsSplit[1].includes(undefined)
            ) {
              this.setState({
                yearQLastError: "Year is required",
                monthQLastError: "Month is required",
                yearQBeginError: "Year is required",
                monthBeginQError: "Month is required",
              });
              pageError = true;
            }
          }
        }
        if (itr.disclosure_qstn_desc.includes("duration")) {
          let disAnsDuration = itr.disclosure_qstn_ans;
          if (
            disAnsDuration === undefined ||
            disAnsDuration.trim().length <= 0
          ) {
            this.setState({
              disAnsDuration: "Specify the duration of your symptoms",
            });
            pageError = true;
          }
        }
        if (itr.disclosure_qstn_desc.includes("treatment")) {
          let disAnsTreatment = itr.disclosure_qstn_ans;
          if (
            disAnsTreatment === undefined ||
            disAnsTreatment.trim().length <= 0
          ) {
            this.setState({ disAnsTreatment: "Specify your treatment" });
            pageError = true;
          }
        }
        if (itr.disclosure_qstn_desc.includes("recovery")) {
          let disAnsRecovery = itr.disclosure_qstn_ans;
          if (
            disAnsRecovery === undefined ||
            disAnsRecovery.trim().length <= 0
          ) {
            this.setState({
              disAnsRecovery: "Specify your degree of recovery",
            });
            pageError = true;
          }
        }
        if (
          itr.disclosure_qstn_desc.includes("often do you use this substance?")
        ) {
          let disAnsSubs = itr.disclosure_qstn_ans;
          if (disAnsSubs === undefined || disAnsSubs.trim().length <= 0) {
            this.setState({ disAnsSubs: "Specify your substance frequency" });
            pageError = true;
          }
        }
        if (itr.disclosure_qstn_desc.includes("central nervous system")) {
          let disAnsCon = itr.disclosure_qstn_ans;
          if (disAnsCon === undefined || disAnsCon.trim().length <= 0) {
            this.setState({ disAnsCon: "Specify substance(s)" });
            pageError = true;
          }
        }
        if (itr.disclosure_qstn_desc.includes("medications prescribed")) {
          let disAnsPre = itr.disclosure_qstn_ans;
          if (disAnsPre === undefined || disAnsPre.trim().length <= 0) {
            this.setState({ disAnsPre: "Specify condition(s)" });
            pageError = true;
          }
        }
        if (itr.disclosure_qstn_desc.includes("dependency issues or relapse")) {
          let dependencyFlag = itr.disclosure_qstn_ans;
          if (dependencyFlag === undefined || dependencyFlag === "") {
            this.setState({
              dependencyFlagError:
                "Specify if you experienced dependency issues or relapse",
            });
            pageError = true;
          }
        }
        return itr;
      });
    let physicanInfo = JSON.parse(
      JSON.stringify(this.state.physicians_choosen)
    );

    physicanInfo &&
      physicanInfo.map((phy) => {
        if (phy.physician_selected === true) {
          //console.log(phy,phy.lastVisitMonth)
          if (phy.lastVisitMonth === undefined) {
            this.setState({
              lastVisitMonthError: true,
            });
            pageError = true;
          }
          if (phy.lastVisitYear === undefined) {
            this.setState({
              lastVisitYearError: true,
            });
            pageError = true;
          }
        }
        return phy;
      });

    let phySelectedStatus = [];
    if (this.state.physicianAttendedFlag === true) {
      physicanInfo &&
        physicanInfo.map((phycisianData, index) => {
          phySelectedStatus = [
            ...phySelectedStatus,
            phycisianData.physician_selected,
          ];
          return phycisianData;
        });
      //console.log(phySelectedStatus)
      if (phySelectedStatus.every((data) => data === false) === true) {
        this.setState({
          selectMorePhysicians: "Select one or more Physicians",
        });
        pageError = true;
      }
    }
    if (pageError === true) {
      this.setState({
        displayBanner: true,
      });
    }

    if (pageError === false) {
      let phycisian_info = {};
      Object.assign(phycisian_info, {
        physician_attended_flag: this.state.physicianAttendedFlag,
        physicians_choosen: JSON.parse(
          JSON.stringify(this.state.physicians_choosen)
        ),
      });

      let previousURL = _.get(this.props, "location.state.previousPage");
      //let questionId = _.get(this.props,"location.state.questionId")
      let activeQuestion = null;
      if (previousURL === "review") {
        activeQuestion = _.get(this.props, "activeQuestion", ""); //questionId
      } else {
        // console.log(this.props,"qqqqqqqqqqqqqqqqqqqqqqqqq")
        activeQuestion = _.get(this.props, "activeQuestion", "");
      }
      const baseQuestionDet = _.get(
        this.props,
        "prefillData.eoi_data.health_question_info.base_question",
        []
      );
      const baseQuestion = JSON.parse(JSON.stringify(baseQuestionDet));
      let checkedPhysician = [];
      baseQuestion.map((question) => {
        if (question.question_id.toString() === activeQuestion.toString()) {
          question.health_condition.map((condition) => {
            if (condition.condition_id === this.state.refluxData.condition_id) {
              //condition.condition_page_status = "ACTIVE"
              condition.life_entities_dq.map((life) => {
                if (
                  life.life_id ===
                  this.state.refluxData.life_entities_dq.life_id
                ) {
                  if (
                    life.disclosure_types &&
                    this.state.refluxData.life_entities_dq.disclosure_types
                  ) {
                    life.disclosure_types.map((disclosure) => {
                      if (
                        disclosure.disclosure_id ===
                        this.state.refluxData.disclosureData.disclosure_id
                      ) {
                        disclosure.disclosure_page_status = "ACTIVE";
                        disclosure.disclosure_question_info =
                          this.state.refluxData.disclosureData.disclosure_question_info;
                        phycisian_info.physicians_choosen.map((a) => {
                          if (a.physician_selected === true) {
                            checkedPhysician = [...checkedPhysician, a];
                          }
                          if (
                            a.physician_selected === false &&
                            a.disclosure_physician_created === true
                          ) {
                            checkedPhysician = [...checkedPhysician, a];
                          }
                          return a;
                        });
                        phycisian_info.physicians_choosen = checkedPhysician;
                        //console.log(phycisian_info)
                        disclosure.phycisian_info = phycisian_info;
                      } else {
                        disclosure.disclosure_page_status = "INACTIVE";
                      }
                      return disclosure;
                    });
                  }
                } else {
                  life.disclosure_types &&
                    life.disclosure_types.map((disclosure) => {
                      disclosure.disclosure_page_status = "INACTIVE";
                      return disclosure;
                    });
                }
                return life;
              });
            } else {
              condition.life_entities_dq &&
                condition.life_entities_dq.map((life) => {
                  life.disclosure_types &&
                    life.disclosure_types.map((disclosure) => {
                      disclosure.disclosure_page_status = "INACTIVE";
                      return disclosure;
                    });
                  return life;
                });
            }
            return condition;
          });
        } else {
          question.health_condition &&
            question.health_condition.map((condition) => {
              condition.life_entities_dq &&
                condition.life_entities_dq.map((life) => {
                  life.disclosure_types &&
                    life.disclosure_types.map((disclosure) => {
                      disclosure.disclosure_page_status = "INACTIVE";
                      return disclosure;
                    });
                  return life;
                });
              return condition;
            });
        }
        return question;
      });
      // console.log(this.props, "PROPSs")
      // console.log(_.get(this.props, "activeCondition"), _.get(this.props, "countCondition"), "checkdata")
      if (this.props.location.state.previousPage === "reviewEdit") {
        const { id } = this.props.match.params;
        this.props.isFlag1(true);
        if (id && id) {
          this.props.chevron(true);
        }
        this.props.history.push(`/eoi/review/${id}`);
        _.set(this.props, "history.location.state.previousPage", "reviewEdit");
        _.set(
          this.props,
          "prefillData.eoi_data.health_question_info.base_question",
          baseQuestion
        );
        this.props.saveReflexQuestion(
          _.get(this.props, "prefillData", {}),
          this.props,
          previousURL,
          id
        );
      }
      if (this.props.location.state.previousPage === "review") {
        const { id } = this.props.match.params;
        // if( _.get(this.props,"activeCondition") === _.get(this.props,"countCondition")){
        this.props.isFlag1(true);

        if (id && id) {
          this.props.chevron(true);
        }
        // this.props.history.push(`/eoi/review/${id}`)
        //_.set(this.props,"history.location.state.previousPage","review")
        //_.set(this.props,"prefillData.eoi_data.health_question_info.base_question",baseQuestion)
        //this.props.saveReflexQuestion(_.get(this.props,"prefillData",{}),this.props, previousURL,id);
        //}// else {
        // _.set(this.props,"history.location.state.previousPage","review")
        //_.set(this.props,"prefillData.eoi_data.health_question_info.base_question",baseQuestion)
        //this.props.saveReflexQuestion(_.get(this.props,"prefillData",{}),this.props, previousURL,id);
        //}
        _.set(this.props, "history.location.state.previousPage", "review");
        _.set(
          this.props,
          "prefillData.eoi_data.health_question_info.base_question",
          baseQuestion
        );
        this.props.saveReflexQuestion(
          _.get(this.props, "prefillData", {}),
          this.props,
          previousURL,
          id
        );

        if (
          _.get(this.props, "activeCondition") ===
            _.get(this.props, "countCondition") &&
          _.get(this.props, "activeReflux") === _.get(this.props, "reflexCount")
        ) {
          this.props.history.push(`/eoi/review/${id}`);
        }

        // if(this.props.checkInfo === true){

        //     this.props.history.push(`/eoi/review/${id}`)

        // }
      } else {
        _.set(
          this.props,
          "prefillData.eoi_data.health_question_info.base_question",
          baseQuestion
        );
        this.props.saveReflexQuestion(
          _.get(this.props, "prefillData", {}),
          this.props,
          previousURL,
          this.props.isRlid
        );
        //moveToReflexiveQuestion(this.props)
        // console.log("refLid_1-", this.props.isRlid);
      }
    }
    scrollUp();
};

  displayFullName = () => {
    //let name,first_name,last_name,middle_initial
    // if(this.state.previousPage){
    //     if (this.state.refluxData && this.state.refluxData.life_entities_dq){
    //     this.state.refluxData.life_entities_dq.map(life =>  {

    //        if( life.life_id && (life.life_id ===  this.state.refluxData.life_entities_dq.life_id)){

    //         let name = this.state.refluxData && this.state.refluxData.life_entities_dq && this.state.refluxData.life_entities_dq.life_name &&
    //         this.state.refluxData.life_entities_dq.life_name.split(', ')
    //         let first_name = name[1]
    //         let last_name = name[0]
    //         let middle_initial = name[2] ? name[2] : null
    //         return (first_name + " " + (middle_initial ? (middle_initial + " ") : "") + last_name)
    //         }
    //     })
    //     }
    // }
    // else{

    if (
      this.state.refluxData &&
      this.state.refluxData.life_entities_dq &&
      this.state.refluxData.life_entities_dq.life_name
    ) {
      let name =
        this.state.refluxData &&
        this.state.refluxData.life_entities_dq &&
        this.state.refluxData.life_entities_dq.life_name &&
        this.state.refluxData.life_entities_dq.life_name.split(", ");
      let first_name = "";
      let last_name = "";
      let middle_initial = "";
      if (
        _.get(
          this.props,
          "prefillData.eoi_data.benefit_coverage_info.eoi_info.source",
          ""
        ) === "GWDY"
      ) {
        first_name = name.slice(-1);
        last_name = name.length === 3 ? name.slice(0, 2) : name.slice(0, 1);
      } else {
        first_name = name[1];
        last_name = name[0];
        middle_initial = name[2] ? name[2] : null;
      }
      return (
        first_name +
        " " +
        (middle_initial ? middle_initial + " " : "") +
        last_name
      );
    }
  };

  handleBack = () => {
    // console.log(this.props, "backkkkkk")
    let previousURL = _.get(this.props, "location.state.previousPage");
    //     //let activeQuestion  = null;
    if (previousURL === "review") {
      //     let questionId = _.get(this.props,"history.location.state.questionId")
      //     let conditionId = _.get(this.props,"history.location.state.conditionId")
      //     let refluxData = _.get(this.props,"history.location.state.refluxId")

      _.set(this.props, "history.location.state.previousPage", "");
      moveToPreviousRefluxQuestion(this.props);
      //     let a = Object.assign({},this.props)
      //     a.activeCondition = conditionId
      //     a.activeQuestion = questionId
      //     a.activeReflux = refluxData//1
      //     // console.log(a,"previousss")
      //     moveToPreviousRefluxQuestion(a)
    } else {
      moveToPreviousRefluxQuestion(this.props);
    }
  };
  changeToBoolean = (stringValue) => {
    if (stringValue === "true") {
      return true;
    } else {
      return false;
    }
  };

  handleRadio = (e) => {
    //let flag = this.state.physicianAttendedFlag;
    this.setState({
      physicianAttendedFlag: this.changeToBoolean(e.target.value),
      physicianAttendedError: "",
    });
    if (e.target.value === "false") {
      this.setState({
        addPhysician: "",
      });
    }
  };
  getCheckedData = (idFromAPI, phyApi) => {
    let result = false;
    let temp;
    //console.log(this.state.physicians_choosen,this.state.physicians_choosen.length,this.state.addOrEditStatus,idFromAPI,"checkedDattttt",this.state.firstTimeAdd,idFromAPI)
    if (
      this.state.physicians_choosen &&
      this.state.physicians_choosen.length === 0 &&
      this.state.firstTimeAdd === true &&
      idFromAPI !== undefined
    ) {
      //this.state.physicians_choosen.map(a=>{
      //if(a.physicianId === undefined){
      //result = true
      //console.log("111111")
      temp = JSON.parse(JSON.stringify(phyApi));
      temp.physician_selected = true;
      this.setState({
        physicians_choosen: [...this.state.physicians_choosen, temp],
      });
      //}
      //})
    }
    // else{
    if (
      temp !== undefined &&
      this.state.firstTimeAdd === true &&
      idFromAPI !== undefined
    ) {
      result = true;
      this.setState({
        firstTimeAdd: false,
      });
    } else {
      this.state.physicians_choosen.map((phycisian) => {
        if (phycisian.physicianId === idFromAPI) {
          if (phycisian.physician_selected === true) {
            ////console.log("mmmmmmmmmmmmmmmmmmmmmmmm",phycisian.physician_selected)
            // return true
            result = true;
          }
          // else{
          //     result =
          //     //return false
          // }
        }
        // else{
        //     return false
        // }
        return phycisian;
      });
    }
    return result;
  };
  handleLastVisit = (e, index, apiData) => {
    // e.preventDefault();
    //let selectedArray =
    //console.log(e.target.checked)
    // if(e.target.checked === true){
    //     this.setState({
    //         lastVisitCheckedArray : [...this.state.lastVisitCheckedArray,index]
    //     })
    // }
    // else{
    //     this.setState({
    //         lastVisitCheckedArray : this.state.lastVisitCheckedArray.filter(each => each !== index)
    //     })
    // }
    let tempPhycisiansA = JSON.parse(
      JSON.stringify(this.state.physicians_choosen)
    );
    //let tempPhycisiansAPI = JSON.parse(JSON.stringify(_.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[])))
    //console.log(tempPhycisiansA)
    //     let phyData = JSON.parse(JSON.stringify(data))
    // // phyData.physician_selected = !phyData.physician_selected;
    //     //phyData.physician_selected = !phyData.physician_selected;
    //     phyData.physician_selected = e.target.checked
    // //console.log(phyData)
    //     tempPhycisiansA.splice(index, 1, phyData);
    //     //console.log(tempPhycisiansA)

    // tempPhycisiansA.map(data=>{
    //     //console.log(data,index)
    //     if(data.physicianId === index){

    //         data.physician_selected = e.target.checked
    //         break;
    //     }

    //     else if(data.physicianId.includes(index)!==true ){
    //         let temp = JSON.parse(JSON.stringify(apiData))
    //         temp.physician_selected = e.target.checked
    //         tempPhycisiansA = [...tempPhycisiansA,temp]
    //         //console.log(tempPhycisiansA)
    //      //else{
    //          ////console.log("222222222",apidata.physicianId === index ,data.physician_selected,data.physician_selected!==true , data.physician_selected!==false)
    //         // tempPhycisiansAPI.map(apidata => {
    //         //     if(apidata.physicianId === index  && data.physicianId.includes(index)!==true ){
    //         //         //console.log("222222222")
    //         //         apidata.physician_selected = e.target.checked
    //         //         tempPhycisiansA = [...tempPhycisiansA,apidata]
    //         //     }
    //         // })
    //     //}
    //     }
    // })
    let selectedDatafromAPI;
    for (let i = 0; i <= tempPhycisiansA.length; i++) {
      //console.log(tempPhycisiansA[0],//console.log(i))
      if (tempPhycisiansA[i] && tempPhycisiansA[i].physicianId === index) {
        selectedDatafromAPI = tempPhycisiansA[i];

        tempPhycisiansA[i].physician_selected = e.target.checked;
        //console.log(tempPhycisiansA)
        break;
      }
    }
    //console.log(selectedDatafromAPI)
    if (selectedDatafromAPI === undefined) {
      let temp = JSON.parse(JSON.stringify(apiData));
      temp.physician_selected = e.target.checked;
      tempPhycisiansA = [...tempPhycisiansA, temp];
      //console.log(tempPhycisiansA)
    }

    //let selectedDatafromAPI = {}
    // tempPhycisiansA.map(data => {
    //     if(data.physicianId === index){
    //         data.physician_selected = e.target.checked
    //     }
    // })

    // tempPhycisiansAPI.map(apiData => {
    //     if(apiData.physicianId === index)
    // })

    //console.log(tempPhycisiansA)
    //  let a = Object.assign({},tempPhycisiansA)
    //                             a.physician_selected = e.target.checked
    this.setState({
      physicians_choosen: tempPhycisiansA,
      selectMorePhysicians: "",
    });
    //console.log(_.get(this.props,"prefillData.eoi_data.health_question_info.physicians",[]))
  };
  handleMonthSelection = (e, index) => {
    //let physicians_choosen = [...this.state.physicians_choosen];

    let physicians_choosen = JSON.parse(
      JSON.stringify(this.state.physicians_choosen)
    );
    physicians_choosen[index]["lastVisitMonth"] = e.target.value;
    let key = `physicianMonth.` + index;
    this.setState({ physicians_choosen, [key]: "" });

    let a = [];
    physicians_choosen &&
      physicians_choosen.map((phy) => {
        if (phy.physician_selected === true) {
          a = [...a, phy.lastVisitMonth];
        }
        return phy;
      });
    if (a.includes(undefined) === false) {
      this.setState({
        lastVisitMonthError: false,
      });
    }
  };
  handleYearSelection = (e, index) => {
    let physicians_choosen = JSON.parse(
      JSON.stringify(this.state.physicians_choosen)
    );

    //let physicians_choosen = [...this.state.physicians_choosen];
    physicians_choosen[index]["lastVisitYear"] = e.target.value;

    let keyYear = `physicianYear.` + index;
    this.setState({ physicians_choosen, [keyYear]: "" });
    let b = [];
    physicians_choosen &&
      physicians_choosen.map((phy) => {
        if (phy.physician_selected === true) {
          b = [...b, phy.lastVisitYear];
        }
        return phy;
      });
    if (b.includes(undefined) === false) {
      this.setState({
        lastVisitYearError: false,
      });
    }
  };
  displayHeader = () => {
    if (
      this.state.refluxData &&
      this.state.refluxData.answer_type === "PICKLIST"
    ) {
      if (
        this.state.refluxData.disclosureData &&
        !this.state.refluxData.disclosureData.disclosure_desc
          .toLowerCase()
          .includes("anemia, other") &&
        //&& this.state.refluxData.disclosureData.answer_disclosure_info_value
        (this.state.refluxData.disclosureData.disclosure_desc
          .toLowerCase()
          .includes("other") ||
          this.state.refluxData.disclosureData.disclosure_desc
            .toLowerCase()
            .includes(
              "i consulted a medical professional for reasons not listed above"
            ) ||
          this.state.refluxData.disclosureData.disclosure_desc
            .toLowerCase()
            .includes(
              "i consulted a medical professional for reasons not listed above"
            ))
      ) {
        return this.state.refluxData.condition_name;
      } else {
        return this.state.refluxData?.disclosureData?.disclosure_desc;
      }
    } else {
      return this.state.refluxData?.disclosureData?.disclosure_desc;
    }

    // else if (this.state.refluxData && this.state.refluxData.answer_type === "ENTER_DETAILS") {
    //     return this.state.refluxData.condition_name
    // }
  };
  handleClose = () => {
    this.setState({
      displayBanner: false,
    });
  };
  saveForLater = () => {
    this.setState({ isSaved: true });
  };
  continueEOI = () => {
    this.setState({ isSaved: false });
  };
  leave = () => {
    this.props.history.push({ pathname: `/eoi/success` });
  };

  render() {
    const { refluxData } = this.state;
    // console.log(refluxData,"refluxData");

    let stateZipCode = {};
    if (showCityDetails === "YES") {
      stateZipCode = _.get(this.props, "physicianStateCity", "");
    } else if (
      _.get(this.props, "physician_data.zip_code", "").trim().length <= 0
    ) {
      _.set(this.props, "physicianStateCity.state_province_code", "");
      _.set(this.props, "physicianStateCity.city.city_name", []);
      _.set(this.props, "physicianStateCity.cityFlag", "");
    }
    let stateProvCode = _.get(stateZipCode, "state_province_code", "");
    let cityName = _.get(stateZipCode, "city.city_name", []);
    let zipErrMsg = "";

    if (_.get(this.props, "physicianStateCity.cityFlag", "") === "N") {
      zipErrMsg = "Invalid zip";
      cityName = "";
      stateProvCode = "";
      if (document.getElementById("eoi-city")) {
        document.getElementById("eoi-city").disabled = true;
      }
    }
    if (
      cityName.length === 1 &&
      _.get(this.props, "physicianStateCity.cityFlag", "") === "Y"
    ) {
      if (document.getElementById("eoi-city")) {
        document.getElementById("eoi-city").disabled = true;
      }
    }
    if (
      cityName.length > 1 &&
      _.get(this.props, "physicianStateCity.cityFlag", "") === "Y"
    ) {
      document.getElementById("eoi-city") &&
        document.getElementById("eoi-city").removeAttribute("disabled");
    }
    let dataFromPhysicianAPI = _.get(
      this.props,
      "prefillData.eoi_data.health_question_info.physicians",
      []
    );
    //console.log(dataFromPhysicianAPI,"phyAPI")
    //console.log(this.state.physicians_choosen,"phy_choosen")
    return (
      <>
        {this.state.isSaved === true ? (
          <SaveForLater
            continueEOI={() => this.continueEOI()}
            leave={() => this.leave()}
          />
        ) : null}
        {this.props.backClick === true ? (
          <Warning handleBrowser={() => this.handleBrowser()} />
        ) : null}
        {this.state.displayBanner &&
        (this.state.disAnsRecovery ||
          this.state.disAnsTreatment ||
          this.state.disAnsDuration ||
          this.state.disAnsSubs ||
          this.state.disAnsCon ||
          this.state.yearQError ||
          this.state.monthQError ||
          this.state.disAnsPre ||
          this.state.addPhysician ||
          this.state.yearQBeginError ||
          this.state.monthBeginQError ||
          this.state.monthQLastError ||
          this.state.yearQLastError ||
          this.state.physicianAttendedError ||
          this.state.selectMorePhysicians ||
          this.state.monthQMedError ||
          this.state.yearQMedError ||
          this.state.lastVisitMonthError ||
          this.state.lastVisitYearError ||
          this.state.dependencyFlagError) ? (
          <AlertBanner
            handleClose={() => this.handleClose()}
            errorMessage="Please fill out all required fields."
          />
        ) : null}

        {/* {pageError === true?
                <AlertBanner
                    handleClose ={() =>this.handleClose()}
                    errorMessage="Please fill out all required fields."/> 
                :null
                } */}
        {this.state.showForm ? (
          <PhysicianForm
            handleCancelPopup={this.handleCancelPopup}
            addOrEditSave={this.addOrEditSave}
            physician_data={this.state.physician_data}
            errorValidation={this.state.errorValidation}
            handleDataChange={this.handleDataChange}
            addOrEditStatus={this.state.addOrEditStatus}
            handleDeleteCheckBox={this.handleDeleteCheckBox}
            isDeleteChecked={this.state.isDeleteChecked}
            zipMaxLength={this.state.zipMaxLength}
            changeZip={this.changeZip}
            blurZip={this.blurZip}
            stateProvCode={stateProvCode}
            cityName={cityName}
            zipErrMsg={zipErrMsg}
          ></PhysicianForm>
        ) : null}
        <main className="knockout">
          <div className="row question-bg q-mobile-bg mb-3">
            <div className="container-fluid">
              <div className="prev-link">
                <button
                  className="btn btn-link"
                  onClick={() => this.handleBack()}
                >
                  <i className="fa fa-arrow-left"></i>Back
                </button>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <img
                    src={imagePath("./images/Employer-Gender-Neutral.svg")}
                    alt=""
                    className="employer-neutral-icon float-left"
                  />
                  <h1
                    className="header-icon-padding"
                    style={{
                      fontSize: "2rem",
                      lineHeight: "2.25rem",
                    }}
                  >
                    {this.displayFullName()}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                {refluxData && refluxData.disclosureData && (
                  <h2 className="info-blue" style={{fontSize: "1.5rem", lineHeight: "1.625rem"}}>{this.displayHeader()}</h2>
                )}
              </div>
            </div>
            {this.displayHeader() === this.state.refluxData.condition_name &&
            this.state.refluxData.disclosureData &&
            this.state.refluxData.disclosureData
              .answer_disclosure_info_value ? (
              <div className="row">
                <div className="col-lg-6">
                  <p>
                    {
                      this.state.refluxData.disclosureData
                        .answer_disclosure_info_value
                    }
                  </p>
                </div>
              </div>
            ) : null}

            <div className="row">
              <div className="col-lg-6">
                {refluxData.disclosureData &&
                  refluxData.disclosureData.disclosure_question_info &&
                  refluxData.disclosureData.disclosure_question_info.map(
                    (question) =>
                      //question.disclosure_qstn_desc.includes(refluxData.disclosureData.disclosure_desc)?
                      refluxData.disclosureData.disclosure_desc.includes(
                        question.disclosure_qstn_desc
                      ) ? null : question.disclosure_qstn_desc.includes(
                          "month"
                        ) ||
                        question.disclosure_qstn_desc.includes(
                          "begin using this substance?"
                        ) ||
                        question.disclosure_qstn_desc.includes(
                          "last use this substance?"
                        ) ||
                        question.disclosure_qstn_desc.includes(
                          "start taking these medications?"
                        ) ||
                        question.disclosure_qstn_desc.includes(
                          "dependency issues or relapse"
                        ) ? (
                        <div className="form-group">
                          <label id="month1">
                            {question.disclosure_qstn_desc}
                          </label>
                          <div className="row">
                            <div className="col-sm-6">
                              {question.disclosure_qstn_desc.includes(
                                "month"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.monthQError ? "invalid" : ""
                                  } ${
                                    this.state.monthQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="month"
                                  onChange={(e) => this.handleMonthQ(e)}
                                  value={this.state.monthQ}
                                >
                                  <option value="" selected disabled>
                                    Month
                                  </option>
                                  {months.map((month) => (
                                    <option value={month}>{month}</option>
                                  ))}{" "}
                                </select>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "begin using this substance?"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.monthBeginQError ? "invalid" : ""
                                  } ${
                                    this.state.beginMonthQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="month"
                                  onChange={(e) => this.handleMonthQ(e)}
                                  value={this.state.beginMonthQ}
                                >
                                  <option value="" selected disabled>
                                    Month
                                  </option>
                                  {months.map((month) => (
                                    <option value={month}>{month}</option>
                                  ))}{" "}
                                </select>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "last use this substance?"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.monthQLastError ? "invalid" : ""
                                  } ${
                                    this.state.lastMonthQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="month"
                                  onChange={(e) => this.handleMonthQ(e)}
                                  value={this.state.lastMonthQ}
                                >
                                  <option value="" selected disabled>
                                    Month
                                  </option>
                                  {months.map((month) => (
                                    <option value={month}>{month}</option>
                                  ))}{" "}
                                </select>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "start taking these medications?"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.monthQMedError ? "invalid" : ""
                                  } ${
                                    this.state.medMonthQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="month"
                                  onChange={(e) => this.handleMonthQ(e)}
                                  value={this.state.medMonthQ}
                                >
                                  <option value="" selected disabled>
                                    Month
                                  </option>
                                  {months.map((month) => (
                                    <option value={month}>{month}</option>
                                  ))}{" "}
                                </select>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "dependency issues or relapse"
                              ) ? (
                                <div
                                  className="btn-group"
                                  role="radiogroup"
                                  id="dep"
                                >
                                  <input
                                    type="radio"
                                    id="btnTrue"
                                    name="depe"
                                    value={true}
                                    checked={this.state.dependencyFlag === true}
                                    onChange={(e) => this.handleQuestion(e)}
                                  />
                                  <label htmlFor="btnTrue" className="btn">
                                    Yes
                                  </label>
                                  <input
                                    type="radio"
                                    id="btnFalse"
                                    name="depkkk"
                                    defaultChecked
                                    value={false}
                                    checked={
                                      this.state.dependencyFlag === false
                                    }
                                    onChange={(e) => this.handleQuestion(e)}
                                  />
                                  <label htmlFor="btnFalse" className="btn">
                                    No
                                  </label>
                                </div>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "dependency issues or relapse"
                              ) && this.state.dependencyFlagError ? (
                                <span
                                  className="error"
                                  style={{ width: "440px" }}
                                >
                                  <i className="fas fa-exclamation-circle"></i>
                                  {this.state.dependencyFlagError}
                                </span>
                              ) : null}
                              {/* {this.state.monthQError || this.state.monthBeginQError || this.state.monthQLastError ?
                                                                <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.monthQError}</span> : null} */}
                              {question.disclosure_qstn_desc.includes(
                                "month"
                              ) && this.state.monthQError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>
                                  {this.state.monthQError}
                                </span>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "begin using this substance?"
                              ) && this.state.monthBeginQError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>
                                  {this.state.monthBeginQError}
                                </span>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "last use this substance?"
                              ) && this.state.monthQLastError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>{" "}
                                  {this.state.monthQLastError}
                                </span>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "start taking these medications?"
                              ) && this.state.monthQMedError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>{" "}
                                  {this.state.monthQMedError}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-sm-6">
                              {question.disclosure_qstn_desc.includes(
                                "month"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.yearQError ? "invalid" : ""
                                  } ${
                                    this.state.yearQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="year"
                                  onChange={(e) => this.handleYearQ(e)}
                                  value={this.state.yearQ}
                                >
                                  <option value="" selected disabled>
                                    Year
                                  </option>
                                  {this.state.yearsList.map((year) => (
                                    <option value={year}>{year}</option>
                                  ))}
                                </select>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "begin using this substance?"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.yearQBeginError ? "invalid" : ""
                                  } ${
                                    this.state.beginYearQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="year"
                                  onChange={(e) => this.handleYearQ(e)}
                                  value={this.state.beginYearQ}
                                >
                                  <option value="" selected disabled>
                                    Year
                                  </option>
                                  {this.state.yearsList.map((year) => (
                                    <option value={year}>{year}</option>
                                  ))}
                                </select>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "last use this substance?"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.yearQLastError ? "invalid" : ""
                                  } ${
                                    this.state.lastYearQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="year"
                                  onChange={(e) => this.handleYearQ(e)}
                                  value={this.state.lastYearQ}
                                >
                                  <option value="" selected disabled>
                                    Year
                                  </option>
                                  {this.state.yearsList.map((year) => (
                                    <option value={year}>{year}</option>
                                  ))}
                                </select>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "start taking these medications?"
                              ) ? (
                                <select
                                  className={`form-control ${
                                    this.state.yearQMedError ? "invalid" : ""
                                  } ${
                                    this.state.medYearQ === undefined
                                      ? "placeholder"
                                      : ""
                                  }`}
                                  id={question.disclosure_qstn_id}
                                  name="year"
                                  onChange={(e) => this.handleYearQ(e)}
                                  value={this.state.medYearQ}
                                >
                                  <option value="" selected disabled>
                                    Year
                                  </option>
                                  {this.state.yearsList.map((year) => (
                                    <option value={year}>{year}</option>
                                  ))}
                                </select>
                              ) : null}

                              {question.disclosure_qstn_desc.includes(
                                "month"
                              ) && this.state.yearQError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>
                                  {this.state.yearQError}
                                </span>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "begin using this substance?"
                              ) && this.state.yearQBeginError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>
                                  {this.state.yearQBeginError}
                                </span>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "last use this substance?"
                              ) && this.state.yearQLastError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>
                                  {this.state.yearQLastError}
                                </span>
                              ) : null}
                              {question.disclosure_qstn_desc.includes(
                                "start taking these medications?"
                              ) && this.state.yearQMedError ? (
                                <span className="error">
                                  <i className="fas fa-exclamation-circle"></i>
                                  {this.state.yearQMedError}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="form-group">
                          <label htmlFor={question.disclosure_qstn_id}>
                            {question.disclosure_qstn_desc}
                          </label>
                          {question.disclosure_qstn_desc.includes(
                            "duration"
                          ) ? (
                            <p>
                              Tell us how long you experienced symptoms or if
                              you’re still experiencing symptoms.
                            </p>
                          ) : null}
                          <input
                            className={`form-control ${
                              (question.disclosure_qstn_desc.includes(
                                "duration"
                              ) &&
                                this.state.disAnsDuration) ||
                              (question.disclosure_qstn_desc.includes(
                                "treatment"
                              ) &&
                                this.state.disAnsTreatment) ||
                              (question.disclosure_qstn_desc.includes(
                                "recovery"
                              ) &&
                                this.state.disAnsRecovery) ||
                              (question.disclosure_qstn_desc.includes(
                                "often do you use this substance?"
                              ) &&
                                this.state.disAnsSubs) ||
                              (question.disclosure_qstn_desc.includes(
                                "medications prescribed"
                              ) &&
                                this.state.disAnsPre) ||
                              (question.disclosure_qstn_desc.includes(
                                "central nervous system"
                              ) &&
                                this.state.disAnsCon)
                                ? "invalid"
                                : ""
                            }`}
                            // class={`form-control ${this.state.disAnsDuration || this.state.disAnsTreatment || this.state.disAnsRecovery ||this.state.disAnsSubs ? 'invalid' : ''}`}
                            id={question.disclosure_qstn_id}
                            type="text"
                            onChange={(e) => this.handleQuestion(e)}
                            maxLength={100}
                            value={question.disclosure_qstn_ans}
                            placeholder={
                              question.disclosure_qstn_desc.includes(
                                "treatment"
                              )
                                ? "Medication, therapy, etc."
                                : question.disclosure_qstn_desc.includes(
                                    "recovery"
                                  )
                                ? "E.g., Fully recovered, ongoing recovery"
                                : question.disclosure_qstn_desc.includes(
                                    "often do you use this substance?"
                                  )
                                ? "E.g.,daily, 1-3 times a week, a few times a month"
                                : null
                            }
                          />

                          {/* {question.disclosure_qstn_desc.includes("treatment") &&
                                                        <input
                                                            className={`form-control ${(question.disclosure_qstn_desc.includes("duration") && this.state.disAnsDuration) ||
                                                                (question.disclosure_qstn_desc.includes("treatment") && this.state.disAnsTreatment) ||
                                                                (question.disclosure_qstn_desc.includes("recovery") && this.state.disAnsRecovery) ||
                                                                (question.disclosure_qstn_desc.includes("often do you use this substance?") && this.state.disAnsSubs) ||
                                                                (question.disclosure_qstn_desc.includes("medications prescribed") && this.state.disAnsPre) ||
                                                                (question.disclosure_qstn_desc.includes("central nervous system") && this.state.disAnsCon) ? 'invalid' : ''}`}

                                                            id={question.disclosure_qstn_id} type="text" onChange={(e) => this.handleQuestion(e)} maxLength={100}
                                                            value={question.disclosure_qstn_ans}
                                                            placeholder={question.disclosure_qstn_desc.includes("treatment") ? "List other forms of treatment or therapy here" : null}
                                                        />
                                                    } */}

                          {question.disclosure_qstn_desc.includes("duration") &&
                          this.state.disAnsDuration ? (
                            <span className="error">
                              <i
                                role="img"
                                aria-label="error"
                                className="fas fa-exclamation-circle"
                              ></i>
                              {this.state.disAnsDuration}
                            </span>
                          ) : null}
                          {question.disclosure_qstn_desc.includes(
                            "treatment"
                          ) && this.state.disAnsTreatment ? (
                            <span className="error">
                              <i
                                role="img"
                                aria-label="error"
                                className="fas fa-exclamation-circle"
                              ></i>
                              {this.state.disAnsTreatment}
                            </span>
                          ) : null}
                          {question.disclosure_qstn_desc.includes("recovery") &&
                          this.state.disAnsRecovery ? (
                            <span className="error">
                              <i
                                role="img"
                                aria-label="error"
                                className="fas fa-exclamation-circle"
                              ></i>
                              {this.state.disAnsRecovery}
                            </span>
                          ) : null}
                          {question.disclosure_qstn_desc.includes(
                            "often do you use this substance?"
                          ) && this.state.disAnsSubs ? (
                            <span className="error">
                              <i
                                role="img"
                                aria-label="error"
                                className="fas fa-exclamation-circle"
                              ></i>
                              {this.state.disAnsSubs}
                            </span>
                          ) : null}
                          {question.disclosure_qstn_desc.includes(
                            "central nervous system"
                          ) && this.state.disAnsCon ? (
                            <span className="error">
                              <i
                                role="img"
                                aria-label="error"
                                className="fas fa-exclamation-circle"
                              ></i>
                              {this.state.disAnsCon}
                            </span>
                          ) : null}
                          {question.disclosure_qstn_desc.includes(
                            "medications prescribed"
                          ) && this.state.disAnsPre ? (
                            <span className="error">
                              <i
                                role="img"
                                aria-label="error"
                                className="fas fa-exclamation-circle"
                              ></i>
                              {this.state.disAnsPre}
                            </span>
                          ) : null}
                        </div>
                      )
                  )}
                {/* {this.state.displayPhysician === false ? <> */}
                <div className="form-group">
                  <label htmlFor="mi1">
                    Did you attend a physician in relation to your{" "}
                    {this.displayHeader()}?
                  </label>
                  <div className="btn-group" role="radiogroup" id="mi1">
                    <input
                      type="radio"
                      id="btnYes"
                      name="ssn"
                      value={true}
                      checked={this.state.physicianAttendedFlag === true}
                      onChange={(e) => this.handleRadio(e)}
                    />
                    <label htmlFor="btnYes" className="btn">
                      Yes
                    </label>
                    <input
                      type="radio"
                      id="btnNo"
                      name="ssn"
                      defaultChecked
                      value={false}
                      checked={this.state.physicianAttendedFlag === false}
                      onChange={(e) => this.handleRadio(e)}
                    />
                    <label htmlFor="btnNo" className="btn">
                      No
                    </label>
                  </div>
                  {this.state.physicianAttendedError ? (
                    <span className="error">
                      <i className="fas fa-exclamation-circle"></i>
                      {this.state.physicianAttendedError}
                    </span>
                  ) : null}
                </div>
                {/* </>:null} */}
              </div>
            </div>
            {this.state.physicianAttendedFlag === true && (
              <div className="row">
                <div className="col-lg-6">
                  {dataFromPhysicianAPI.length > 0 ? (
                    <div className="small-label pt-0">
                      Select from your previously entered physician information,
                      or add a new doctor.
                    </div>
                  ) : (
                    <div className="small-label pt-0">
                      Please add your physician's information
                    </div>
                  )}
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-7">
                      {dataFromPhysicianAPI &&
                        dataFromPhysicianAPI.length > 0 && (
                          <div className="outline">
                            {dataFromPhysicianAPI &&
                              dataFromPhysicianAPI.map(
                                (phycisianData, index) => (
                                  //this.state.physicians_choosen && this.state.physicians_choosen.map((phycisian, index) => (
                                  <div
                                    className={
                                      index % 2 === 0
                                        ? "physician-card"
                                        : "physician-card background"
                                    }
                                  >
                                    <div className="checkbox">
                                      <input
                                        type="checkbox"
                                        id={"checkbox1" + index}
                                        checked={this.getCheckedData(
                                          phycisianData.physicianId,
                                          phycisianData
                                        )}
                                        onChange={(e) =>
                                          this.handleLastVisit(
                                            e,
                                            phycisianData.physicianId,
                                            phycisianData
                                          )
                                        }
                                      />
                                      <label htmlFor={"checkbox1" + index}>
                                        <div className="small-label p-0">
                                          {phycisianData.name}
                                        </div>
                                        <div className="detail">
                                          {phycisianData.speciality}
                                        </div>
                                        <div className="detail">
                                          {phycisianData.addressLine1},{" "}
                                          {phycisianData.addressLine2}
                                          <br />
                                          {/* <div className="detail">{phycisianData.addressLine2}<br /> */}
                                          {phycisianData.city},{" "}
                                          {phycisianData.ui_state_cd}{" "}
                                          {phycisianData.zip}
                                          {/* </div> */}
                                        </div>
                                        <div className="detail">
                                          {phycisianData.phone}
                                        </div>
                                      </label>
                                    </div>
                                    <button
                                      className="btn btn-outline small"
                                      onClick={() =>
                                        this.onEditPhysician(
                                          this.getPhyData(
                                            phycisianData.physicianId,
                                            phycisianData
                                          ),
                                          index,
                                          phycisianData.physicianId
                                        )
                                      }
                                    >
                                      <text>Edit</text>{" "}
                                      <span class="offscreen">
                                        {phycisianData.name}
                                      </span>{" "}
                                      <text>details</text>
                                    </button>
                                  </div>
                                  // ))
                                )
                              )}
                          </div>
                        )}
                      {/* } */}
                      <div className="form-group">
                        {this.state.selectMorePhysicians ? (
                          <span className="error">
                            <i class="fas fa-exclamation-circle"></i>
                            {this.state.selectMorePhysicians}
                          </span>
                        ) : null}
                      </div>

                      <div className="form-group">
                        {this.state.addPhysician ? (
                          <span className="error">
                            <i className="fas fa-exclamation-circle"></i>
                            {this.state.addPhysician}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-5">
                      {dataFromPhysicianAPI &&
                        dataFromPhysicianAPI.map((phycisianData, index) => (
                          //this.state.physicians_choosen.map((phycisianData, index) => (
                          // phycisianData.lastVisitDateExplicitlyUnknown===true &&
                          <div className="physician-card checked">
                            {/* {this.state.lastVisitCheckedArray.map(data => ( */}
                            {this.state.physicians_choosen.map(
                              (phycisian, index) =>
                                // data === index && phycisian.physicianId === phycisianData.physicianId && phycisian.physician_selected===true  ?
                                phycisian.physicianId ===
                                  phycisianData.physicianId &&
                                phycisian.physician_selected === true ? (
                                  // phycisian.physician_selected===true?
                                  <fieldset className="row form-group">
                                    <legend
                                      htmlFor="visit-1"
                                      className="col-lg-12"
                                    >
                                      When did you last visit{" "}
                                      {phycisianData.name} for this condition?
                                    </legend>
                                    <div className="col-sm-6">
                                      <select
                                        aria-label="Month"
                                        aria-describedby={
                                          "visit-M" + index + "-error"
                                        }
                                        className={`form-control ${
                                          _.get(
                                            this.state,
                                            `physicianMonth.` + index
                                          )
                                            ? "invalid"
                                            : ""
                                        } ${
                                          !phycisian.lastVisitMonth
                                            ? "placeholder"
                                            : ""
                                        }`}
                                        id={"visit-M" + index}
                                        value={phycisian.lastVisitMonth}
                                        onChange={(e) =>
                                          this.handleMonthSelection(e, index)
                                        }
                                      >
                                        <option
                                          aria-label="Month"
                                          value=""
                                          selected
                                          disabled
                                        >
                                          Month
                                        </option>
                                        {months.map((month) => (
                                          <option value={month}>{month}</option>
                                        ))}
                                      </select>
                                      {/* {_.get(this.state, `physicianMonth.`+index) ?
                                                            <span className="error"><i class="fas fa-exclamation-circle"></i>{_.get(this.state, `physicianMonth.`+index)}</span> : null } */}

                                      {this.state.lastVisitMonthError ===
                                        true &&
                                      phycisian.physician_selected === true &&
                                      phycisian.lastVisitMonth === undefined ? (
                                        <span
                                          id={"visit-M" + index + "-error"}
                                          className="error"
                                        >
                                          <i
                                            role="img"
                                            aria-label="error"
                                            class="fas fa-exclamation-circle"
                                          ></i>
                                          Month is required
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="col-sm-6">
                                      <select
                                        aria-label="Year"
                                        aria-describedby={
                                          "visit-Y" + index + "-error"
                                        }
                                        className={`form-control ${
                                          _.get(
                                            this.state,
                                            `physicianYear.` + index
                                          )
                                            ? "invalid"
                                            : ""
                                        } ${
                                          !phycisian.lastVisitYear
                                            ? "placeholder"
                                            : ""
                                        }`}
                                        id={"visit-Y" + index}
                                        value={phycisian.lastVisitYear}
                                        onChange={(e) =>
                                          this.handleYearSelection(e, index)
                                        }
                                      >
                                        <option
                                          aria-label="Year"
                                          value=""
                                          selected
                                          disabled
                                        >
                                          Year
                                        </option>
                                        {this.state.yearsList.map((year) => (
                                          <option value={year}>{year}</option>
                                        ))}
                                      </select>
                                      {/* {_.get(this.state, `physicianYear.`+index) ?
                                                            <span className="error"><i class="fas fa-exclamation-circle"></i>{_.get(this.state, `physicianYear.`+index)}</span> : null } */}
                                      {this.state.lastVisitYearError === true &&
                                      phycisian.physician_selected === true &&
                                      phycisian.lastVisitYear === undefined ? (
                                        <span
                                          id={"visit-Y" + index + "-error"}
                                          className="error"
                                        >
                                          <i
                                            role="img"
                                            aria-label="error"
                                            class="fas fa-exclamation-circle"
                                          ></i>
                                          Year is required
                                        </span>
                                      ) : null}
                                    </div>
                                  </fieldset>
                                ) : null
                            )}
                            {/* ))} */}
                          </div>
                          //))
                        ))}
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group"></div>
                        <button
                          className="btn btn-link blue-link pt-4 pb-5"
                          onClick={() => this.onAddPhysician()}
                        >
                          + Add a physician
                        </button>
                        <div class="form-group"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-12">
                <hr />
                <div className="field-group  table-header-group">
                  {_.get(this.props, "trustedFlag", "") === true ? (
                    <button
                      className="btn btn-link"
                      onClick={() => this.saveForLater()}
                    >
                      Save for later
                    </button>
                  ) : null}
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => this.handleNext()}
                  >
                    {(_.get(this.props, "location.state.previousPage") ===
                      "review" &&
                      _.get(this.props, "location.state.previousPage") ===
                        "review" &&
                      _.get(this.props, "activeCondition") ===
                        _.get(this.props, "countCondition") &&
                      _.get(this.props, "activeReflux") ===
                        _.get(this.props, "reflexCount")) ||
                    this.props.location.state.previousPage === "reviewEdit"
                      ? "Return to review page"
                      : "Next"}
                  </button>
                  {/* <button class="btn btn-primary float-right" onClick={() => this.handleNext()}>
                                        {console.log("PROPS",this.props)}
                                        {(_.get(this.props,"location.state.previousPage") === "review") &&(_.get(this.props,"location.state.previousPage") === "review" &&  _.get(this.props,"activeCondition") === _.get(this.props,"countCondition")) && ((_.get(this.props, "activeReflux") === _.get(this.props, "reflexCount"))) ?
                                    "Return to review page":"Next"}
                                </button> */}
                  {/* <button class="btn btn-primary float-right" onClick={() => this.handleNext()}>
                                        {console.log("PROPS",this.props)}
                                        {(_.get(this.props,"location.state.previousPage") === "review") ||(_.get(this.props,"location.state.previousPage") === "review" &&  _.get(this.props,"activeCondition") === _.get(this.props,"countCondition")) ?
                                    "Return to review page":"Next"}
                                </button> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

//export default ReflexQuestion;
export default connect(
  (state) => state.eoiStore,
  (dispatch) => bindActionCreators(actionCreators, dispatch)
)(ReflexQuestion);
